import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { API } from "../../../api-config.js";
import User from "../../../contexts/UserContext";
import "../../../style/partials/Students/_upcomingSessionS.scss";
import { useNavigate } from "react-router-dom";
import { VscCalendar } from "react-icons/vsc";
import socket from "../../../socket/socketio";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import SectionsHead from "../../Home/Heading.js";

let offset = 0;
const UpcomingStudentSession = () => {
  const { userData } = useContext(User);
  console.log(userData);
  const [data, setData] = useState("");
  const [len, setLength] = useState(0);
  const [isDisplayed, setIsDisplayed] = useState();
  const [sessions, setSessions] = useState([]);
  const navigate = useNavigate();

  const requestPay = async (item, session, slotNumber) => {
    console.log("REQUESTED", item, session);

    const response = await axios.post(
      `${API}/api/upcomingSession/requestPay`,
      {
        userId: userData.userId,
        bid: item,
        slotNumber: slotNumber,
      },
      {
        headers: {
          token: userData.token,
        },
      }
    );
    console.log("COMPLETE CHECK RESPONSE");
    socket.emit(`completeCheck`, { user: item.userId._id });
  };
  const userActiveSessions = async () => {
    const userIden = userData.userId;
    const response = await axios.get(
      `${API}/api/upcomingSession/getSessionsCounsellor`,
      {
        headers: {
          token: userData.token,
        },
        params: { userIden, offset: offset },
      }
    );
    setSessions(response.data.allUpcoming);
    setLength(Math.ceil(response.data.count / 10));
  };
  useEffect(() => {
    try {
      console.log(userData);
      if (!userData.isProfile) {
        navigate("/Counsellor");
      }
      userActiveSessions();
    } catch (error) {
      console.log("ERROR :: " + error);
    }
  }, []);

  useEffect(() => {
    socket.on(`completeCheck${userData.userId}`, () => {
      userActiveSessions();
    });

    return () => {
      socket.off(`completeCheck${userData.userId}`);
    };
  }, [socket]);
  const toggleDisplay = (index) => {
    setIsDisplayed(index);
  };

  return (
    <>
      <section className="container">
        <SectionsHead heading="Upcoming Sessions" />
        {sessions.length > 0 ? (
          sessions?.map((item, index) => {
            console.log(item);
            return (
              <div
                className=" shadow-sm container consellor-card-dtl mt-4 mb-4"
                key={index}
                onClick={() => toggleDisplay(index)}
              >
                <div className="row">
                  <div className="col-md-11 col-10 heading">
                    <div className="counselling-diteal-open">
                      <i className="fa-solid fa-star"></i>
                      <div>
                        <p>{`Counselling On ${item.sessiontitle} To ${
                          item.userId && item.userId.fName
                        } `}</p>
                        <p className="sessionCount">
                          Number Of Sessions : {item.sessionSlots.length}
                        </p>
                        <p className="sessionHour">1 Hour/Session</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-1 col-2 calender">
                    <VscCalendar></VscCalendar>
                  </div>
                  <div className="container">
                    {index === isDisplayed ? (
                      <div className="row calender-content">
                        <div className=" counselD col-12 col-md-12">
                          <p>
                            <b>DESCRIPTION :</b>
                            {item.sessionDes}
                          </p>
                        </div>
                        {item.sessionSlots.map((items, i) => {
                          console.log(
                            items,
                            "Object.entries(items)",
                            Object.entries(items)
                          );
                          const sessionElements = Object.entries(items).map(
                            ([key, session], indexes) => (
                              <div
                                key={key}
                                className="session text-left col-md-6 col-lg-4 col-12"
                              >
                                <div className="session-card shadow-sm">
                                  <h2>{session.sessionTitle}</h2>
                                  <p>Start Date: {session.startDate}</p>
                                  <p>Start Time: {session.fromHour}</p>
                                  {/* <p>To Hour: {session.toHour}</p> */}
                                  <p>Slot Description: {session.sesDes}</p>
                                  {item.sessionDetails[0][indexes].paid && (
                                    <button className="completed">
                                      Completed
                                    </button>
                                  )}
                                  {!item.sessionDetails[0][indexes].paid &&
                                    !item.sessionDetails[0][indexes]
                                      .requestPay && (
                                      <button
                                        onClick={() => {
                                          requestPay(item, items, indexes);
                                        }}
                                      >
                                        Request Pay
                                      </button>
                                    )}
                                  {!item.sessionDetails[0][indexes].paid &&
                                    item.sessionDetails[0][indexes]
                                      .requestPay && (
                                      <button className="completed">
                                        Payment Pending
                                      </button>
                                    )}
                                </div>
                              </div>
                            )
                          );
                          return sessionElements;
                        })}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="heading">No Pending Sessions</div>
        )}
        {len > 1 && (
          <div>
            <button
              onClick={() => {
                if (offset <= 0) {
                  return;
                } else {
                  offset -= 1;
                  userActiveSessions();
                }
              }}
            >
              Prev
            </button>
            <button
              onClick={() => {
                if (offset >= len - 1) {
                  return;
                } else {
                  console.log("LENGTH", len);
                  offset += 1;
                  userActiveSessions();
                }
              }}
            >
              Next
            </button>
          </div>
        )}
      </section>
    </>
  );
};

export default UpcomingStudentSession;
