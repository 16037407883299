import React, { useEffect, useContext, useState, useRef } from "react";
import { API } from "../../../api-config";
import axios from "axios";
import UserContext from "../../../contexts/UserContext";
import Heading from "../../Home/Heading";

import "../../../style/partials/Counsellor/transaction.scss";

let transacOffset = 0;
let pageNums = 2;
let direction = 1;
const CounseleeTransaction = () => {
  const { userData } = useContext(UserContext);
  const [transacPages, setTransacPages] = useState();
  const [transacts, setTransacts] = useState();
  const [trueIndex, setTrueIndex] = useState();
  const closeRef = useRef();
  const loadTransactions = async () => {
    try {
      const transactions = await axios.get(
        `${API}/api/transaction/get-userTransactions`,
        {
          headers: {
            userId: userData.userId,
            offset: transacOffset,
            token: userData.token,
          },
        }
      );
      console.log("TRANSACTIONSSS", transactions);
      setTransacts(transactions.data.alltransactions);
      setTransacPages(Math.ceil(parseInt(transactions.data.count) / 8));
    } catch (error) {
      console.log("ERROR IN TRANSACTIONS", error);
    }
  };
  useEffect(() => {
    loadTransactions();
  }, [transacOffset]);
  const pageNum = async (num) => {
    console.log("num", num);
    let limit = 8;
    let offset = num - 1;
    const transactions = await axios.get(
      `${API}/api/transaction/get-userTransactions`,
      {
        headers: {
          userId: userData.userId,
          offset: offset,
          token: userData.token,
        },
      }
    );
    console.log(transactions);
    setTransacts(transactions.data.alltransactions);
    direction = num;
  };
  const accomodatePage = (clickedPage) => {
    if (clickedPage <= 1) {
      return clickedPage + 1;
    }
    if (clickedPage >= transacPages) {
      return clickedPage - 1;
    }
    return clickedPage;
  };
  //To , Conversion From Currency to Currency , +/-Amount , conversion rate , Date
  return (
    <div className="container">
      <Heading heading="Transactions" />
      <div className="row mb-5">
        {transacts && transacts.length > 0 ? (
          transacts.map((item, index) => (
            <div className="col-md-12">
              <div className="trans-slip col-md-12 shadow-sm">
                <div className="row">
                  <div className="d-flex col-md-6 ">
                    <b>Date :</b>{" "}
                    <p>{new Date(item?.createdAt).toString().slice(4, 15)}</p>
                  </div>
                  <div className="d-flex col-md-6 ">
                    <b>To : </b>
                    <p onClick={() => setTrueIndex(index)}>{`${
                      item?.to?.fName ? item?.to?.fName : item?.to?.entity
                    }`}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="d-flex col-md-6 ">
                    <b>Via :</b>
                    <p>
                      {item?.transactionVia !== "WALLET" &&
                      item?.transactionVia !== "To WALLET" ? (
                        <p>{` From ${item?.transactionVia} To ${item?.toCurrency}`}</p>
                      ) : item?.transactionVia === "WALLET" ? (
                        <p>{` Transferred From ${item?.fromCurrency} To ${item?.toCurrency}`}</p>
                      ) : item?.transactionType === "CREDIT" ? (
                        <p>{` Added To ${item?.toCurrency} Wallet`}</p>
                      ) : (
                        <p>{` Deducted From ${item?.fromCurrency} Wallet`}</p>
                      )}
                    </p>
                  </div>
                  <div className="d-flex col-md-6 ">
                    <b>Amount :</b>
                    <p>
                      {item.transactionType == "DEBIT" ? (
                        <p className="amount">
                          <span className="dr-amount">-</span>
                          {` ${item?.amount} ${item?.fromCurrency}`}
                        </p>
                      ) : (
                        <p className="amount">
                          <span className="cr-amount">+</span>
                          {` ${item?.amount} ${item?.toCurrency}`}
                        </p>
                      )}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="d-flex col-md-12 ">
                    <p>
                      {item?.session?.sessiontitle && (
                        <div>
                          <b>Session :</b> {item?.session?.sessiontitle}
                        </div>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <h6>No Transactions Available</h6>
        )}
        {transacPages > 1 && (
          <div className="container text-center">
            {direction > 1 && (
              <button
                className="index-btn mb-2 mt-2"
                onClick={() => {
                  pageNum(direction - 1 >= 1 ? direction - 1 : 1);
                }}
              >
                <i className="fa-solid fa-chevron-left"></i>
              </button>
            )}
            {(() => {
              const buttons = [];
              if (transacPages >= 3) {
                for (let i = -1; i < 2; i++) {
                  const currentPageNum = accomodatePage(direction);
                  buttons.push(
                    <button
                      key={currentPageNum + i}
                      className="index-btn mb-2 mt-2"
                      onClick={() => {
                        pageNum(currentPageNum + i);
                      }}
                    >
                      {currentPageNum + i}
                    </button>
                  );
                }
              } else {
                for (let i = 0; i < transacPages; i++) {
                  buttons.push(
                    <button
                      key={i + 1}
                      className="index-btn mb-2 mt-2"
                      onClick={() => {
                        pageNum(i + 1);
                      }}
                    >
                      {i + 1}
                    </button>
                  );
                }
              }
              return buttons;
            })()}

            {direction != transacPages && (
              <button
                className="index-btn mb-2 mt-2"
                onClick={() => {
                  pageNum(
                    direction + 1 <= transacPages ? direction + 1 : transacPages
                  );
                }}
              >
                <i className="fa-solid fa-chevron-right"></i>
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CounseleeTransaction;

{
  /* <div> */
}
{
  /* {transacPages <= 3
            ? (() => {
                const buttons = [];
                for (let i = 1; i <= transacPages; i++) {
                  buttons.push(
                    <button
                      onClick={() => {
                        pageNum = i;
                        transacOffset = pageNum - 1;
                        console.log(
                          "pageNum",
                          pageNum,
                          "offset",
                          transacOffset
                        );
                        loadTransactions();
                      }}
                    >
                      {i}
                    </button>
                  );
                }
                return buttons;
              })()
            : (() => {
                const buttons = [];
                for (let i = -1; i < 2; i++) {
                  buttons.push(
                    <button
                      onClick={() => {
                        if (pageNum + i <= transacPages && pageNum + i >= 2) {
                          pageNum = pageNum + i;
                          transacOffset = pageNum - 1;
                          console.log(
                            "pageNum",
                            pageNum,
                            "offset",
                            transacOffset
                          );
                          loadTransactions();
                        }
                      }}
                    >
                      {pageNum + i}
                    </button>
                  );
                }
                return buttons;
              })()} */
}
// </div>
