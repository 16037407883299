import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Typography } from "@mui/material";
// import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SectionsHead from "../../Home/Heading";
import "..//..//..//style/partials/Students/_aboutcounselling.scss";
import Button from "../../Elements/Button";

function Aboutcounselling() {
  const [expanded, setExpanded] = React.useState(false);
  const [show, setShow] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <>
        <div className="container mt-4 mb-5">
          <SectionsHead heading="FAQs" />
          <Accordion
            className="acor-abut"
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              className="acor-summ"
              expandIcon={<ExpandMoreIcon className="acor-ico" />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography
                className="acor-titl"
                sx={{ flexShrink: 0 }}
                component="span"
              >
                What happens if a counsellor rejects my bid as a counselee?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="footer-link" component="span">
                If a counsellor rejects your bid, you can explore other
                available sessions or adjust your bidding preferences for a
                better match.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className="acor-abut"
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              className="acor-summ"
              expandIcon={<ExpandMoreIcon className="acor-ico" />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography
                className="acor-titl"
                sx={{ flexShrink: 0 }}
                component="span"
              >
                Can I bid for counselling sessions as a counselee?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="footer-link" component="span">
                Yes, counselees can bid on sessions by entering their preferred
                price. Counsellors review and respond to counselee bids,
                accepting or rejecting them.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className="acor-abut"
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              className="acor-summ"
              expandIcon={<ExpandMoreIcon className="acor-ico" />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Typography
                className="acor-titl"
                sx={{ flexShrink: 0 }}
                component="span"
              >
                What types of counselling sessions are available for users on
                A2Z?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="footer-link" component="span">
                A2Z offers a broad range of counselling sessions, covering areas
                such as career growth, financial well-being, IT proficiency,
                personal development, and more.
              </Typography>
            </AccordionDetails>
          </Accordion>
          {show && (
            <div>
              <Accordion
                className="acor-abut"
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}
              >
                <AccordionSummary
                  className="acor-summ"
                  expandIcon={<ExpandMoreIcon className="acor-ico" />}
                  aria-controls="panel4bh-content"
                  id="panel4bh-header"
                >
                  <Typography
                    className="acor-titl"
                    sx={{ flexShrink: 0 }}
                    component="span"
                  >
                    How can I bid for counselling sessions as a counselee?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="footer-link" component="span">
                    Explore available counselling sessions, enter your preferred
                    price in the bidding process, and submit your bid.
                    Counsellors will review and respond, accepting or rejecting
                    based on mutual agreement.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className="acor-abut"
                expanded={expanded === "panel5"}
                onChange={handleChange("panel5")}
              >
                <AccordionSummary
                  className="acor-summ"
                  expandIcon={<ExpandMoreIcon className="acor-ico" />}
                  aria-controls="panel4bh-content"
                  id="panel4bh-header"
                >
                  <Typography
                    className="acor-titl"
                    sx={{ flexShrink: 0 }}
                    component="span"
                  >
                    What payment options are available for users on A2Z?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="footer-link" component="span">
                    Secure payment options include debit cards, credit cards,
                    net banking, UPI, and wallets. Choose the method that suits
                    you best.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className="acor-abut"
                expanded={expanded === "panel6"}
                onChange={handleChange("panel6")}
              >
                <AccordionSummary
                  className="acor-summ"
                  expandIcon={<ExpandMoreIcon className="acor-ico" />}
                  aria-controls="panel4bh-content"
                  id="panel4bh-header"
                >
                  <Typography
                    className="acor-titl"
                    sx={{ flexShrink: 0 }}
                    component="span"
                  >
                    How do I schedule counselling sessions with counsellors?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="footer-link" component="span">
                    After your bid is accepted, collaborate with counsellors to
                    schedule session times and dates. Sessions can be conducted
                    within our platforms with ease.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className="acor-abut"
                expanded={expanded === "panel7"}
                onChange={handleChange("panel7")}
              >
                <AccordionSummary
                  className="acor-summ"
                  expandIcon={<ExpandMoreIcon className="acor-ico" />}
                  aria-controls="panel4bh-content"
                  id="panel4bh-header"
                >
                  <Typography
                    className="acor-titl"
                    sx={{ flexShrink: 0 }}
                    component="span"
                  >
                    Is there a limit to the number of counselling sessions I can
                    bid on?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="footer-link" component="span">
                    No, there is no specific limit. You can bid on multiple
                    sessions based on your preferences and needs.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className="acor-abut"
                expanded={expanded === "panel8"}
                onChange={handleChange("panel8")}
              >
                <AccordionSummary
                  className="acor-summ"
                  expandIcon={<ExpandMoreIcon className="acor-ico" />}
                  aria-controls="panel4bh-content"
                  id="panel4bh-header"
                >
                  <Typography
                    className="acor-titl"
                    sx={{ flexShrink: 0 }}
                    component="span"
                  >
                    How can I explore new counselling sessions on A2Z?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="footer-link" component="span">
                    Navigate to the "Explore Sessions" section to discover
                    available counselling sessions. Use filters to find sessions
                    that match your preferences and needs.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          )}
          <div className="det-button mt-3 w-100">
            <Button
              onClick={() => {
                setShow((prevState) => !prevState);
              }}
              type="button"
              className="btn btn-outline-primary custom-btn btn-8"
              context={show ? "Show Less" : "Show More"}
            />
          </div>
        </div>
      </>
    </div>
  );
}
export default Aboutcounselling;
