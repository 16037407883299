import React, { useState, useEffect, useContext, Suspense, lazy } from "react";
import "../..//..//style/partials/Students/Home.scss";
import first from "../..//../images/img1.png";
import second from "../..//../images/img2.png";
import third from "../..//../images/img3.png";
import SectionsHead from "../../Home/Heading";
import { API } from "../../../api-config";
import UserContext from "../../../contexts/UserContext";
import axios from "axios";
import Loader from "../../Loader/Loader";
import { useLocation } from "react-router-dom";
import Counsellorslider from "../../sliders/Counsellorslider";
const Myproposal = lazy(() => import("../Studentsession/Myproposal"));
const CounseleeDashboard = lazy(() =>
  import("../Dashboard/CounseleeDashboard")
);

function Student() {
  const emp = useLocation();

  const [biddata, setbiddata] = useState("");
  const { userData } = useContext(UserContext);

  useEffect(() => {
    const updateUsers = async () => {
      let myData = await axios.post(
        `${API}/api/bidrequest/get-bid-request`,
        {
          userId: userData.userId,
        },
        { $headers: { token: userData.token } }
      );
      setbiddata(myData.data);
    };
    updateUsers();
  }, []);
  // console.log(biddata,"test")

  if (biddata.length === 0) {
    return (
      <>
        <SectionsHead heading="Our Top Counsellor" />
        <Counsellorslider />
      </>
    );
  } else {
    return (
      <>
        <Suspense fallback={<Loader />}>
          <CounseleeDashboard />
          <Myproposal />
        </Suspense>
      </>
    );
  }
}
export default Student;
