import React, { useState, useEffect } from "react";
import "..//..//..//style/partials/Students/_banner.scss";
import back from "..//../../images/section3.png";
import { FiSearch } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useAPI } from "../../../api/apiskills";

function Studentbanner() {
  const Navigate = useNavigate();
  const [lists, setLists] = useState([]);
  const { skills } = useAPI();
  const routeChange = () => {
    Navigate("/select");
    console.log("test");
  };
  const searchTyping = () => {
    const results = document.getElementById("resulting");
    results.classList.toggle("display");
  };
  const getCounsellors = (item) => {
    const skill = item;
    Navigate(`/Session-Result`, { state: skill });
  };
  const skillsFilter = () => {
    console.log(skills);
    const inps = document.getElementById("keyseacrh");
    if (inps.value.trim() !== "") {
      const ltrd = skills.filter((skill) =>
        skill.skills.includes(`${inps.value.toUpperCase()}`)
      );
      setLists(ltrd);
    }
  };
  const getKey = () => {
    const inps = document.getElementById("keyseacrh");
    if (!inps.value) {
      return;
    }
    const skill = inps.value;
    console.log("SKILL", skill);
    Navigate(`/Session-Result`, { state: skill });
  };
  return (
    <>
      <section className="mb-4">
        <div className="cover-section">
          <div className="cover-content">
            <div className="inn-txt container">
              <h2 className="tag-heading">Take Advice Once</h2>
              <h3>Grow Your Future.</h3>
              <div className="search-box">
                <input
                  type="search"
                  id="keyseacrh"
                  name="keysearch"
                  onClick={() => {
                    searchTyping();
                  }}
                  onChange={() => {
                    skillsFilter();
                  }}
                  onKeyPress={(e) => {
                    if (e.key == "Enter" && e.target.value.trim() != "") {
                      getKey();
                    }
                  }}
                  placeholder="Start the search for your guidance.."
                />
                <FiSearch
                  className="search-icon"
                  onClick={() => {
                    getKey();
                  }}
                />
                <div className="searchResults" id="resulting">
                  {lists &&
                    lists.map((item, index) => (
                      <button
                        onClick={() => {
                          getCounsellors(item.skills);
                        }}
                      >
                        {item.skills}
                      </button>
                    ))}
                </div>
              </div>
              <button className="std-hm-btn" onClick={routeChange}>
                Find a Counseller or Sessions
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Studentbanner;
