/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from "react";
import menuItems from "../../data/MenuItems";
import { FaUserAlt } from "react-icons/fa";
import { IoMdNotificationsOutline } from "react-icons/io";
import { HiMenu } from "react-icons/hi";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import Logo from "./Logo";
import "../..//style/partials/components/_header.scss";

let screenWidth = window.innerWidth;
function Navbar() {
  const [active, setActive] = useState(false);
  const [showText, setShowText] = useState(false);
  const dropdownRef = useRef(null);
  const handleClick = () => {
    if (window.innerWidth > 991) return;
    setActive(!active);
  };
  const handleclose = () => {
    setActive(false);
  };
  const handleExpand = () => {
    const search = document.querySelector(".search-input");
    search.classList.toggle("search-expanded");
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowText(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <>
      <nav className="navbar container-fluid">
        <div className="col-lg-10 col-md-10  col-9 d-flex-costomize">
          <div
            className="menu-icon col-lg-10 col-md-6 col-6"
            onClick={handleClick}
          >
            {/* <HiMenu className="menu" /> */}
            <div className="menu-list nav-link-costomize">
              {menuItems.map((item, index) => {
                return (
                  <Link
                    key={index}
                    to={item.url}
                    className={item.cName}
                    onClick={handleclose}
                  >
                    {item.title}
                  </Link>
                );
              })}
            </div>
            <i className={active ? "fas fa-times" : "fas fa-bars"}></i>
          </div>
          <div className=" ">
            <Logo />
            <ul className={active ? "nav-menu active" : "nav-menu"}>
              <li className="header-logo-sticky">
                <h3>A2Z</h3>
                <a href="#" className="fas fa-times" onClick={handleclose}>
                  <AiOutlineClose></AiOutlineClose>
                </a>
              </li>
              {menuItems.map((item, index) => {
                return (
                  <li key={index}>
                    <Link
                      to={item.url}
                      className={item.cName}
                      onClick={handleclose}
                    >
                      {item.title}
                    </Link>
                  </li>
                );
              })}
              {/* <li>
              <Sub/>
            </li> */}
            </ul>
          </div>
        </div>
        <div>
          <div className="dropdown" ref={dropdownRef}>
            <button className="dropbtn" onClick={() => setShowText(!showText)}>
              <FaUserAlt />
            </button>
            {showText && (
              <div className="dropdown-content">
                <Link
                  to="/Login
                "
                  onClick={() => setShowText(!showText)}
                >
                  Login
                </Link>
                <Link to="/Register" onClick={() => setShowText(!showText)}>
                  Signup
                </Link>
              </div>
            )}
          </div>
        </div>
        {/* <Button>SIGN UP</Button> */}
      </nav>
    </>
  );
}

export default Navbar;
