const Studentmenu = [
  {
    title: "My Proposals",
    url: "/Students",
    cName: "nav-links",
  },
  {
    title: "Top Skills",
    url: "/topskills",
    cName: "nav-links",
  },
  {
    title: "Top Counsellors",
    url: "/select",
    cName: "nav-links",
  },
  {
    title: "Upcoming Sessions",
    url: "/UpcomingSessionStudent",
    cName: "nav-links",
  },
  {
    title: "Completed Sessions",
    url: "/CompletedSession",
    cName: "nav-links",
  },
  {
    title: "About US",
    url: "/Aboutus",
    cName: "nav-links",
  },
  // {
  //   title: "FAQs",
  //   url: "/faqs",
  //   cName: "nav-links",
  // },
];

export default Studentmenu;
