import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  Suspense,
} from "react";
import UserContext from "../../../contexts/UserContext";
import iimmgg from "../../../images/man@3x.png";
import axios from "axios";
import { API } from "../../../api-config";
import { IoLocationSharp } from "react-icons/io5";
import Loader from "../../Loader/Loader";
// import "../../../style/partials/Counsellor/_dashboard.scss";
import "../../../style/partials/Counsellor/counsellor-all-pages.scss";
import moment from "moment";
import { Rating } from "react-simple-star-rating";
import Earnings from "./Earnings";
import InfiniteCarousel from "react-leaf-carousel";
import SectionsHead from "../../Home/Heading";
const Counsellor = React.lazy(() => import("../Home/Counsellor"));

const Dash = () => {
  const { userData, setUserData } = useContext(UserContext);
  const [counsellorDetails, setCounsellorDetails] = useState("");
  const [counsellorData, setCounsellorData] = useState();
  const [ratingDetails, setRatingDetails] = useState([]);
  const [skills, setSkills] = useState([]);
  const [feedbackDetails, setFeedbackDetails] = useState([]);
  const [wallets, setWallets] = useState([]);
  // console.log("USER DATA DETAILS", userData);
  const dashboardData = async () => {
    const { data } = await axios.post(
      `${API}/api/frontregi/counsellordashboard`,
      { userId: userData.userId }
    );
    console.log("DASHBOARD DETAILS", data);
    setCounsellorDetails(data.response[0].firstDetails[0]);
    setSkills(data.response[0].skillsunwinded[0].skills);
    setRatingDetails(data.response[0].feedbackFacet);
    console.log(data.response[0].firstDetails[0].feedback);
    setFeedbackDetails(data.response[0].firstDetails[0].feedback);

    const res = await axios.get(
      `${API}/api/wallet/get-amounts/${userData.userId}`,
      {
        userId: userData.userId,
        headers: {
          token: userData.token,
        },
      }
    );
    setWallets((prevState) => res.data);
    let myData = await axios.get(`${API}/api/counselor-profile/getCounselor`, {
      params: { id: userData.userId },
      headers: {
        token: userData.token,
      },
    });
    setCounsellorData((prevState) => myData?.data[0]);
  };
  useEffect(() => {
    if (userData.flag == "COUNSELLOR") {
      dashboardData();
    }
  }, []);
  return (
    <Fragment>
      <div className="counsellor-dashboard">
        {counsellorDetails && (
          <div className="container-fluid bg-light introduction pt-5 pb-5">
            <div className="container">
              <div className="row">
                <div
                  className="col-lg-3 col-xl-2"
                  style={{ textAlign: "center" }}
                >
                  {counsellorData && (
                    <img
                      src={counsellorData?.profilePic[0]}
                      className="dashPic rounded-circle border-success couns-img"
                      alt="Profile Pic"
                      loading="lazy"
                      height="60"
                      width="60"
                    ></img>
                  )}
                </div>
                <div className="profileDetails col-lg-9 col-xl-10">
                  <div className="row">
                    <div className="col-sm-6">
                      <label style={{ fontWeight: "600" }}>Name :</label>
                      <p
                        style={{
                          color: "#2a578d",
                          fontSize: "22px",
                          textTransform: "capitalize",
                        }}
                      >
                        {counsellorDetails?.fName}
                      </p>
                      <label style={{ fontWeight: "600" }}>Rating :</label>
                      <p>
                        {Math.ceil(counsellorDetails?.ratings[0]?.ratingstar)}
                      </p>
                    </div>

                    <div className="col-sm-6">
                      <label style={{ fontWeight: "600" }}>
                        Total Sessions :
                      </label>
                      <p>{`(${counsellorDetails?.ratings[0]?.totalsessions})`}</p>
                      <label style={{ fontWeight: "600" }}>Location :</label>
                      <p>
                        <IoLocationSharp />
                        {counsellorDetails?.counsellor[0]?.country}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-0 mt-lg-4">
                <div className="col-md-12 profileDetails">
                  <label style={{ fontWeight: "600" }}>About Me :</label>
                  <p>{counsellorDetails?.counsellor[0]?.description}</p>
                  <label style={{ fontWeight: "600" }}>Skills :</label>
                  <div className="d-flex flex-wrap">
                    {skills.map((item, index) => (
                      <span className="p-1" key={index}>
                        {item.skill}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {wallets && <Earnings userdetails={wallets} />}
        <Suspense fallback={<Loader />}>
          {userData.isProfile && <Counsellor />}
        </Suspense>

        <section className="sub-heading">
          <SectionsHead heading="Portfolio" />
          <div className="container">
            {counsellorDetails &&
            counsellorDetails.counsellor[0].portfolio.length > 0 ? (
              <div className="row">
                <InfiniteCarousel
                  breakpoints={[
                    {
                      breakpoint: 500,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                      },
                    },
                    {
                      breakpoint: 991,
                      settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                      },
                    },
                    {
                      breakpoint: 1400,
                      settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                      },
                    },
                    {
                      breakpoint: 2000,
                      settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                      },
                    },
                  ]}
                  dots={false}
                  showSides={true}
                  sidesOpacity={0.5}
                  sideSize={0.1}
                  slidesToScroll={2}
                  slidesToShow={2}
                  scrollOnDevice={true}
                >
                  {counsellorDetails.counsellor[0].portfolio.map(
                    (item, index) => {
                      return (
                        <div className="  d-flex justify-content-center ">
                          <div className="category-content-box ">
                            <div className="image-sub-headings">
                              <img
                                src={item}
                                alt="subhead"
                                loading="lazy"
                              ></img>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
                </InfiniteCarousel>
              </div>
            ) : (
              <p>No categories available</p>
            )}
          </div>
        </section>

        <div className="pt-5 pb-5">
          {counsellorDetails && (
            <div className="reviews container text-center pb-3">
              {counsellorDetails?.ratings[0]?.totalsessions > 0 && (
                <p style={{ fontWeight: "600", fontSize: "28px" }}>
                  {counsellorDetails?.ratings[0]?.totalsessions > 1
                    ? counsellorDetails?.ratings[0]?.totalsessions + " Reviews"
                    : counsellorDetails?.ratings[0]?.totalsessions +
                      " Review"}{" "}
                </p>
              )}

              <div className="container">
                <div className="row">
                  {/* <div className="col-md-3"></div> */}
                  {counsellorDetails?.ratings[0]?.totalstar > 0 && (
                    <div className="col-md-12 col-lg-8 m-auto">
                      <div className="well well-sm">
                        <div className="row">
                          <div className="col-xs-12 col-md-6">
                            <div className="row rating-desc">
                              {ratingDetails?.map((item, index) => (
                                <>
                                  <div className="col-2 col-md-3 text-sm-right">
                                    <span className="fa fa-star"></span>
                                    {item._id}
                                  </div>
                                  <div className="col-10 col-md-9">
                                    <div className="progress">
                                      <div
                                        className="progress-bar progress-bar-success"
                                        role="progressbar"
                                        aria-valuenow="20"
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                        style={{
                                          width: `${
                                            (Number(item.count) /
                                              Number(
                                                counsellorDetails?.ratings[0]
                                                  ?.totalsessions
                                              )) *
                                            100
                                          }%`,
                                        }}
                                      >
                                        {/* <span className="sr-only">19%</span> */}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ))}
                            </div>
                          </div>
                          <div className="col-xs-12 col-md-6 text-center">
                            <h1 className="rating-num">
                              {Math.ceil(
                                counsellorDetails?.ratings[0]?.ratingstar
                              )}
                            </h1>
                            <div className="rating">
                              <Rating
                                readonly={true}
                                initialValue={Math.ceil(
                                  counsellorDetails?.ratings[0]?.ratingstar
                                )}
                              />
                            </div>
                            <div>
                              <span className="fa fa-user"></span>
                              {counsellorDetails?.ratings[0]?.totalstar} Total
                              stars
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* <div className="col-md-3"></div> */}
                </div>
              </div>
            </div>
          )}

          <div className="feedbacks container">
            {feedbackDetails &&
              feedbackDetails?.map((item, index) => (
                <div className="p-2row">
                  <div className="linepart"></div>
                  <div className="review-box ">
                    <div className="review-info-div row">
                      <div className=" col-4 col-sm-1">
                        <div>
                          <img
                            src={
                              item?.StudentDetails[0]?.profilePic[0]
                                ? item?.StudentDetails[0]?.profilePic[0]
                                : iimmgg
                            }
                            style={{
                              height: "100%",
                              width: "100%",
                              borderRadius: "50px",
                            }}
                            alt="reviews"
                            loading="lazy"
                            className=" "
                          ></img>
                        </div>
                      </div>
                      <div className="col-8 col-sm-11 ">
                        <div className="review-info d-sm-flex d-block">
                          <div>
                            <p className="font-weight-bold  reviewer">{`${item?.studentId?.fName}`}</p>
                            <p className="ago-months ">
                              {moment(new Date(item.createdAt)).fromNow()}
                            </p>
                          </div>
                          <div>
                            <Rating
                              initialValue={item?.counsellorRating}
                              readonly={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="description mt-2 col-12">
                        <p>{item?.feedbackDesc}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Dash;
