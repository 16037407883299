import React, { useEffect, useContext } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { API } from "../../../api-config";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import "../../../style/partials/Counsellor/_conformation.scss";
import Loader from "../../Loader/Loader";
import UserContext from "../../../contexts/UserContext";
import { useLocation } from "react-router-dom";
import useRazorpay from "react-razorpay";
import socket from "../../../socket/socketio";
import { Modal } from "react-bootstrap";
import { forEach, head } from "lodash";
import alertify from "alertifyjs";
import SectionsHead from "../../Home/Heading";
import { throttle } from "lodash";
import Button from "../../Elements/Button";

let waitAccept = false;
let waitReject = false;
let sufficient = "";
let amountToBeAdded = 0;
let selectedSkill = [];
let waitAdd = false;
function Conformation() {
  const navigate = useNavigate();
  const [Razorpay] = useRazorpay();
  const [biddata, Setbiddata] = useState([]);
  const {
    register,
    formState: { errors },
    trigger,
    setValue,
    handleSubmit,
    clearErrors,
  } = useForm({
    mode: "onChange",
  });
  const [rejecteRequested, setRejectRequested] = useState(false);
  const [user, Setuser] = useState([]);
  const [scheduled, setScheduled] = useState([]);
  const [skill, Setskill] = useState([]);
  const [selected, SetSelected] = useState([]);
  const { userData } = useContext(UserContext);
  const [show, setShow] = useState(false);
  const [isPaymentDone, setPayment] = useState(false);
  const [sessionSkills, setSessionSkills] = useState(null);
  const [Total, setTotal] = useState(0);
  const [inputList, SetInputList] = useState([
    { sessions: `Slot ${1}`, Price: 0, description: "" },
  ]);
  const [sessionSlots, setSessionSlots] = useState(1);
  let pricePattern = /[0]*/;

  const emp = useLocation();
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    SetInputList(list);
  };

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    SetInputList(list);
    setSessionSlots((prevState) => prevState - 1);
    let test = Total - inputList[index].Price;
    setTotal((prevState) => prevState - inputList[index].Price);
    setTotal((prevState) => {
      console.log(prevState);
      return prevState;
    });
    // let Tots = 0;
    // inputList.map((item) => {
    //   console.log("item.price", item.Price);
    //   Tots -= Number(item.Price);
    //   console.log("tot", Tots);
    // });
  };

  const handleAddClick = (i) => {
    const errorRes = formValidation(inputList);
    if (errorRes) {
      SetInputList([...inputList, { sessions: `Slot ${i + 2}`, Price: "" }]);
    } else {
      console.log("test");
    }
    setSessionSlots((prevState) => prevState + 1);
  };
  const updateUsers = async () => {
    await axios
      .post(`${API}/api/bidrequest/counsellor-bid-request`, {
        sessionid: emp.state,
      })
      .then((res) => {
        console.log("BID DATA ", res.data);
        Setbiddata(res.data);
      });
  };
  console.log("EMP", emp);
  useEffect(() => {
    const getNonScheduled = async () => {
      const scheduleData = await axios.get(
        `${API}/api/bidrequest/nonscheduled`,
        { headers: { userId: userData.userId, session: emp.state } }
      );
      setScheduled(scheduleData.data);
      console.log(scheduleData.data);
    };
    getNonScheduled();
    socket.on(`requestProp${userData.userId}`, () => {
      try {
        updateUsers();
      } catch (error) {
        console.log("ERRORS IN SOCKET", error);
      }
    });
    updateUsers();
    return () => {
      socket.off(`requestProp${userData.userId}`);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);
  const consid = userData.userId;
  // console.log("test", biddata.id)
  useEffect(() => {
    try {
      const updateData = async () => {
        await axios
          .get(`${API}/api/session/get-session/${emp.state}`, {
            headers: { token: userData.token },
          })
          .then((res) => {
            console.log("SESSION DETAILS", res.data);
            Setuser(res.data);
            setSessionSkills((prevState) => res.data.skills);
          });

        let myData = await axios.post(
          `${API}/api/counselor-profile/get-counselor-skill`,
          {
            userId: userData.userId,
          }
        );
        console.log("SSKKIILLSS   ", myData.data.skills);
        Setskill(myData.data.skills);
      };
      updateData();
    } catch (error) {
      console.log("error in getting session", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (user.length === 0 || isPaymentDone) {
    return (
      <div>
        <Loader />
      </div>
    );
  }
  const formValidation = (inputList) => {
    try {
      const data = [...inputList];
      let validSess = true;
      let validPrice = true;
      let validDesc = true;
      for (let index = 0; index < data.length; index++) {
        if (
          data[index].Price === "" ||
          data[index].Price < 500 ||
          pricePattern.test(data[index].Price)
        ) {
          data[index].PriceCheck = "Enter valid price";
          validPrice = false;
        } else {
          data[index].PriceCheck = "";
          validPrice = true;
        }
        if (data[index].Price < 500) {
          data[index].PriceCheck = "Price should be above 499";
          validPrice = false;
        } else {
          data[index].PriceCheck = "";
          validPrice = true;
        }
        if (data[index].description == "") {
          data[index].sessionsCheck = " Description required";
          validDesc = false;
        }
      }
      SetInputList(data);
      return validSess && validPrice && validDesc;
    } catch (error) {
      console.log(error);
    }
  };

  const sessionUpdate = (e) => {
    const errorRes = formValidation(inputList);
    if (errorRes) {
      console.log("ALL SLOTS  ", sessionSlots);
      console.log("TOTAL CHARGES  ", userData);
      let Tot = 0;
      inputList.map((item) => {
        console.log("Item Price", item.Price);
        Tot += Number(item.Price);
        console.log("tot", Tot);
      });
      try {
        axios
          .post(
            `${API}/api/session/update-session`,
            {
              sessionId: user._id,
              counsellorId: userData.userId,
              sessiontitle: e.sessiontitle,
              skills: sessionSkills,
              sessionNumber: inputList,
              sessionDes: e.sessiondes,
              sessionCost: Tot,
              defaultCurrency: userData.defaultCurrency,
              slotCount: sessionSlots,
            },
            {
              headers: {
                token: userData.token,
              },
            }
          )
          .then((res) => {
            console.log(res);
            socket.emit("newSession");
            navigate("/Counsellor");
          });
      } catch (err) {
        console.log(err);
        alertify.error(err.response.data.status);
      }
    } else {
      alertify.error("Enter complete details");
    }
  };

  const checkBalance = async (bidId) => {
    console.log(bidId);
    const resd = await axios.get(`${API}/api/wallet/checkBalance`, {
      headers: {
        sessionId: user._id,
        entity: "counsellor",
        userId: userData.userId,
        token: userData.token,
        bidId: bidId,
      },
    });
    console.log(resd.data);
    sufficient = resd.data.sufficient;
    amountToBeAdded = resd.data.amountToBeAdded;
    return resd.data.sufficient;
  };
  const scheduleSession = (id, bidAmount, userid, item) => {
    let info = {
      bidId: id,
      counsellorId: item.counselorid._id,
      userId: userid._id,
      sessionId: user._id,
      sessiontitle: user.sessiontitle,
      skills: user.skills,
      sessionDes: user.sessionDes,
      noOfSessions: item.sessionNumber,
      sessionDetails: item.sessionSlots,
    };

    navigate(`/Eventcreate`, {
      state: {
        state: info,
        sessions: user.sessionNumber,
        leftBal: item.bidAmount,
      },
    });
  };
  const Accept = async (id, bidAmount, userid, item) => {
    try {
      console.log(item, "BIDID", id);
      let message = `${item.counselorid.fName} ${item.counselorid.lName} Accepted your counselling request on ${item.sessionid.sessiontitle}`;

      let notificationDetails = {
        msg: message,
        entity: "Session",
      };
      let ids = { bidAmount: amountToBeAdded, bidId: id };
      // console.log("FID BID ID", id, bidAmount, userid, item);
      // START FROM HERE PEHLE CHECK BALANCE JAYEGA FIR PAYMENT HOGA FIR ACCEPT HOGA
      let paymentDecision = await checkBalance(id);
      // console.log(paymentDecision);
      if (paymentDecision) {
        navigate("/SessionPay", {
          state: {
            session: user,
            _id: userid._id,
            msg: message,
            defaultCurrency: user.defaultCurrency,
            details: notificationDetails,
            userData: userid,
            data: emp,
            entity: "counsellor",
            bidId: id,
            // counsellorId: response.data.counselorId,
            sessiontitle: user.sessiontitle,
          },
        });
      } else {
        console.log("paymentDecision", paymentDecision);
        let paymentDetails = await axios.get(
          `${API}/api/wallet/pay-counselling`,
          {
            params: {
              id: ids,
            },
            headers: {
              userId: userData.userId,
              entity: "counsellor",
              sessionId: emp.state,
              token: userData.token,
              bidId: id,
            },
          }
        );
        console.log(" Math.ceil(amountToBeAdded)", Math.ceil(amountToBeAdded));
        var options = {
          key: process.env.RAZORPAY_KEY_ID,
          order_id: paymentDetails.data.order.id,
          amount: Math.ceil(amountToBeAdded),
          // currency: `${userData.defaultCurrency}`,
          currency: `INR`,
          name: "Ooze IT Solutions Pvt Ltd",
          handler: async function (paymentDetails) {
            try {
              debugger;
              setPayment((prevState) => !prevState);
              console.log(paymentDetails);
              const status = "SUCCESS";
              let details = {
                ...paymentDetails,
                ...ids,
                status,
                consid,
                _id: user._id,
              };

              const res = await axios.put(
                `${API}/api/bidrequest/confirm-bidrequest/${id}`,
                {
                  headers: {
                    token: userData.token,
                  },
                },
                {
                  headers: {
                    token: userData.token,
                  },
                }
              );
              console.log(res, user.sessionNumber, item);
              let info = {
                bidId: res.data.id,
                counsellorId: item.counselorid._id,
                userId: userid._id,
                sessionId: user._id,
                sessiontitle: user.sessiontitle,
                skills: user.skills,
                sessionDes: user.sessionDes,
                noOfSessions: item.sessionNumber,
                sessionDetails: item.sessionSlots,
              };
              console.log("CONFIRMED BID REQUEST", amountToBeAdded);
              const addTransaction = await axios.post(
                `${API}/api/transaction/insert-transaction`,
                {
                  data: {
                    ...details,
                    bidReqId: id,
                    userId: { _id: item.counselorid._id },
                    amount: amountToBeAdded,
                    bidAmount: amountToBeAdded,
                    sessionId: user._id,
                    counselorid: { _id: item.counselorid._id },
                    currency: user.defaultCurrency,
                    transactionType: "CREDIT",
                    transactionVia: "Razorpay",
                  },
                },
                { headers: { token: userData.token } }
              );
              //amount add hoga as per currency
              console.log("REACHED HERE", addTransaction);
              const addMoneyToWallet = await axios.put(
                `${API}/api/wallet/add-amounts/${userData.userId}`,
                {
                  bidId: id._id,
                  amount: amountToBeAdded,
                  userId: userData.userId,
                  sessionId: emp.state,
                },
                {
                  headers: {
                    token: userData.token,
                  },
                }
              );
              // console.log("id._idid._idid._id", id, id._id);
              const resWal = await axios.post(
                `${API}/api/wallet/paid-via-wallet`,
                null,
                {
                  headers: {
                    userId: userData.userId,
                    bidId: id,
                    sessionId: emp.state,
                    bidCost: Number(0.03 * item.bidAmount),
                    sessionCurrency: user.defaultCurrency,
                    entity: "counsellor",
                    token: userData.token,
                  },
                }
              );

              const notificationResponse = await axios.post(
                `${API}/api/notifications/update`,
                {
                  details: notificationDetails,
                  userData: userid,
                },
                { headers: { token: userData.token } }
              );
              console.log("userid IS", userid);
              console.log(notificationResponse);
              socket.emit("noti", {
                userId: userid._id,
                bidId: id,
                item: item,
                _id: userid._id,
                msg: message,
              });
              socket.emit("sessionDecision", {
                userId: userid._id,
                bidId: id,
                item: item,
                _id: userid._id,
                msg: message,
                status: "ACCEPTED",
              });
              navigate(`/Eventcreate`, {
                state: {
                  state: info,
                  sessions: user.sessionNumber,
                  leftBal: item.bidAmount,
                },
              });
              // console.log(
              //   "RES.DATA.ID",
              //   res.data.id,
              //   "counsellorId",
              //   item.counselorid._id,
              //   "userId",
              //   userid._id,
              //   "sessionId",
              //   user._id,
              //   "sessiontitle",
              //   user.sessiontitle,
              //   "user.skills",
              //   user.skills,
              //   "user.sessionDes",
              //   user.sessionDes,
              //   "item.sessionNumber",
              //   item.sessionNumber,
              //   "item.sessionSlots",
              //   item.sessionSlots,
              //   "...details",
              //   ...details,
              //   "bidReqId: id",
              //   id,
              //   " userId: { _id: userid._id }",
              //   userid._id,
              //   "amountToBeAdded",
              //   amountToBeAdded,
              //   "user.defaultCurrency",
              //   user.defaultCurrency,
              //   "bidId: id._id",
              //   id._id,
              //   "sessionId: emp.state",
              //   emp.state,
              //   "userId: userData.userId",
              //   userData.userId,
              //   "bidCost: Number(0.03 * user.sessionCost)",
              //   Number(0.03 * user.sessionCost),
              //   "sessionCurrency: user.defaultCurrency",
              //   user.defaultCurrency,
              //   " entity",
              //   "counsellor"
              // );
              setPayment(false);
              alert("Payment Successful");
              // navigate("/Students");
            } catch (error) {
              console.log(error);
            }
          },
        };
        const rzp1 = new Razorpay(options);
        rzp1.open();
        rzp1.on("payment.failed", async (response) => {
          const status = "FAILED";
          let details = { ...response, ...id, status };
          const addTransaction = await axios.post(
            `${API}/api/transaction/insert-transaction`,
            { data: details }
          );
          alert(response.error.code);
        });
        console.log(paymentDetails);
      }
    } catch (err) {
      console.log(err, "Accept");
    }
  };

  const Reject = async (id, bidAmount, userid, item) => {
    console.log("SBKUCH", id, userid, item);
    let message = `${userData.fName} ${userData.lName} Rejected your counselling request on ${item.sessionid.sessiontitle}`;
    let notificationDetails = {
      msg: message,
      entity: "Session",
    };

    try {
      axios
        .put(`${API}/api/bidrequest/reject-bidrequest/${id}`, null, {
          headers: {
            token: userData.token,
          },
        })
        .then((res) => {
          console.log(res, "deletd");
          Setbiddata((prevState) => prevState.filter((item) => item._id != id));
        });
      const notificationResponse = await axios.post(
        `${API}/api/notifications/update`,
        { details: notificationDetails, userData: userid },
        {
          headers: {
            token: userData.token,
          },
        }
      );
      socket.emit("noti", {
        userId: userid,
        bidId: id,
        item: item,
        _id: userid._id,
        msg: message,
      });
      socket.emit("sessionDecision", {
        userId: userid,
        bidId: id,
        item: item,
        _id: userid._id,
        msg: message,
        status: "REJECTED",
      });
    } catch (err) {
      console.log(err, "Raject");
    }
  };

  const EditSession = () => {
    setShow(true);
    console.log("IITTEEMM   ", user);
    setValue("sessiontitle", user.sessiontitle);
    setValue("sessiondes", user.sessionDes);
    SetInputList(user.sessionNumber);
  };

  const filterSessionSkills = (item) => {
    console.log(sessionSkills, item);
    setSessionSkills((prevState) =>
      prevState.filter((eitem) => item._id != eitem._id)
    );
  };
  const addSessionSkills = (item) => {
    if (!sessionSkills.some((prod) => prod._id == item._id)) {
      setSessionSkills((prevState) => [...sessionSkills, item]);
    }
  };
  return (
    <section className="request-confirm-div container pt-5 pb-5">
      <SectionsHead heading="Session INFO" />
      <div className="container session-detailing">
        <div className="session-box p-3 shadow rounded mt-3 mb-3">
          {/* <div className="session-heading mb-3"></div> */}
          <div className="row">
            <div className="col-12">
              <div className="row sess-clr">
                <div className="col-md-6 col-12 ">
                  <div className="shadow-sm session-dtl-list-1  mt-3 mb-3">
                    <p className="fw-bold">
                      Session Name : <strong>{user.sessiontitle}</strong>
                    </p>
                  </div>
                </div>

                <div className="col-md-6 col-12 ">
                  <div className="shadow-sm session-dtl-list mt-3 mb-3">
                    <p className="fw-bold">
                      Created date :{" "}
                      <strong>
                        {new Date(user.createdAt).toDateString().slice(4, 15)}
                      </strong>
                    </p>
                  </div>
                </div>
              </div>

              <div className="row">
                {user.sessionNumber.length > 0 &&
                  user.sessionNumber?.map((item, index) => {
                    console.log("TIHSISITEM", item);
                    return (
                      <div className="col-md-6">
                        <div className="shadow-sm session-dtl-list mt-3 mb-3">
                          <div className=" sess-clr" key={index}>
                            <p key={item?.id} className="fw-bold">
                              Session No. : <strong>{item?.sessions}</strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {user.sessionNumber.length > 0 &&
                  user.sessionNumber?.map((item, index) => {
                    console.log("TIHSISITEM", item);
                    return (
                      <div className="col-md-6">
                        <div className="shadow-sm session-dtl-list mt-3 mb-3">
                          <div className="sess-clr">
                            <p className="fw-bold">
                              Price :{" "}
                              <strong>{`${item?.Price} ${user.defaultCurrency}`}</strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="row">
                <div className="col-md-12 ">
                  <div className=" shadow-sm session-dtl-list mt-3 mb-3">
                    <p className="text-justify mt-1">
                      <span className="fw-bold">Skills : </span>
                      {sessionSkills && sessionSkills
                        ? sessionSkills.map((item) => {
                            console.log(item);
                            return <label id="result">{item?.skill}</label>;
                          })
                        : null}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 ">
                  <div className=" shadow-sm session-dtl-list mt-3 mb-3">
                    <p className="text-justify mt-1">
                      <span className="fw-bold">Desrciption : </span>
                      {user?.sessionDes?.charAt(0).toUpperCase() +
                        user?.sessionDes?.slice(1)}
                    </p>
                  </div>
                </div>
                <div className="col-md-12">
                  <button
                    className="col-2 edit-btn"
                    onClick={() => EditSession()}
                  >
                    Edit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {biddata.length > 0 &&
          biddata?.map((item, index) => {
            console.log("AAIIITTTEEEMMM", item);
            console.log(scheduled, scheduled.includes(item._id));
            return (
              <div
                className="session-more-detail shadow rounded mt-3 mb-3"
                key={index}
              >
                <div className="row">
                  <div className="mt-1 mb-1 col-4">
                    <div className="shadow-sm session-dtl-list-1">
                      <h6>
                        Bid By :{" "}
                        {item.userId.fName.charAt(0).toUpperCase() +
                          item.userId.fName.slice(1)}{" "}
                        {/* {item.counselorid.lName.charAt(0).toUpperCase() +
                        item.counselorid.lName.slice(1)}{" "} */}
                      </h6>
                    </div>
                  </div>
                  <div className="mt-1 mb-1 col-4">
                    <div className="shadow-sm session-dtl-list-1">
                      <h6>For Session : {item.sessionNumber}</h6>
                    </div>
                  </div>
                  <div className="mt-1 mb-1 col-4">
                    <div className="shadow-sm session-dtl-list-1">
                      <h6>
                        Bid Price :{" "}
                        {`${item.bidAmount} ${user.defaultCurrency}`}
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 mt-3">
                  <div className="bid-btn col-lg-12">
                    {scheduled.includes(item._id) && (
                      <button
                        className="btn-primary schedule-btn"
                        onClick={() => {
                          scheduleSession(
                            item._id,
                            item.bidAmount,
                            item.userId,
                            item
                          );
                        }}
                      >
                        Schedule
                      </button>
                    )}
                    {item.conformation === true ? (
                      <div>
                        {" "}
                        <button
                          type="button"
                          id=""
                          className="accept-btn"
                          //onClick={() => Accept(item._id,item.bidAmount,item.userId)}
                        >
                          ACCEPTED
                        </button>
                      </div>
                    ) : (
                      <>
                        <div>
                          {" "}
                          <button
                            type="button"
                            id=""
                            className="accept-btn"
                            onClick={() => {
                              if (waitAccept) {
                                return;
                              }
                              waitAccept = true;
                              setTimeout(() => {
                                waitAccept = false;
                              }, 4000);
                              Accept(
                                item._id,
                                item.bidAmount,
                                item.userId,
                                item
                              );
                            }}
                          >
                            ACCEPT
                          </button>
                        </div>
                        <div>
                          <button
                            type="button"
                            className="reject-btn"
                            onClick={() => {
                              if (waitReject) {
                                return;
                              }
                              setRejectRequested((prevState) => true);
                              waitReject = true;
                              setTimeout(() => {
                                waitReject = false;
                                setRejectRequested((prevState) => false);
                              }, 4000);
                              Reject(
                                item._id,
                                item.bidAmount,
                                item.userId,
                                item
                              );
                            }}
                          >
                            REJECT
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <Modal show={show} className="pl-0">
        <Modal.Header
          closeButton
          onClick={() => {
            setShow(false);
          }}
        />
        <SectionsHead heading="Session Detail" />
        <Modal.Body>
          <form
            onSubmit={handleSubmit((data) => {
              if (waitAdd) {
                return;
              }
              waitAdd = true;
              setTimeout(() => {
                waitAdd = false;
              }, 4000);
              sessionUpdate(data);
            })}
          >
            <div className="">
              <label className="session-label mt-2">
                Write a title of your session?
              </label>
              <input
                type="text"
                onKeyPress={(e) => {
                  if (e.target.value === "" && e.key === " ") {
                    e.preventDefault();
                  }
                }}
                {...register("sessiontitle", { required: true })}
                id="ses-title"
              />
              {errors.sessiontitle && (
                <p className="valid-prof">This field is Required</p>
              )}
            </div>
            <div className="">
              <label className="session-label mt-2">
                Write Description of your session?
              </label>
              <textarea
                {...register("sessiondes", { required: true })}
                type="text"
                onKeyPress={(e) => {
                  if (e.target.value === "" && e.key === " ") {
                    e.preventDefault();
                  }
                }}
                className="mt-2"
                id="ses-des"
              />
              {errors.sessiondes && (
                <p className="valid-prof">This field is Required</p>
              )}
            </div>

              <div className="check-skill">
                <h6 className="session-label mt-2">Selected skills for the session</h6>
                {sessionSkills && sessionSkills.length > 0
                ? sessionSkills.map((item) => (
                    <label id="result">
                      {item?.skill}
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          filterSessionSkills(item);
                        }}
                      >
                        X
                      </button>
                    </label>
                  ))
                : null}
              </div>

              {/* <label className="session-label mt-2" id="session-skill">
                Selected skills for the session
                <br></br>
                
              </label> */}
              
                <div className="check-skill">
                  <h6 className="session-label mt-2">Your Skills</h6>
                  {skill &&
                    skill.map((item, index) => (
                      <label
                        key={`${item}${index}`}
                        onClick={() => {
                          addSessionSkills(item);
                        }}
                      >
                        {item.skill}
                      </label>
                    ))}
                  {errors?.skills?.type === "required" && (
                    <p className="valid-prof">Please Select Session</p>
                  )}
                  {/* <p>vale:{selected}</p> */}
                </div>

            <div className=" det-button">
              <h6 className="session-label mt-2 text-start-mdfy">
                Estimate the Number and Price of your session
                <span style={{ color: "red" }}>*</span>
              </h6>
              {inputList.map((x, i) => {
                return (
                  <div className="bid-list row" key={x.id}>
                    <div className="valid-sess col-lg-4">
                      <input
                        type="number"
                        name="sessions"
                        className="bid-item"
                        placeholder={`Slot ${i + 1}`}
                        value={`Slot ${i}`}
                        readOnly
                        onChange={(e) => handleInputChange(e, i)}
                      />
                    </div>
                    <div className="valid-sess col-lg-4">
                      <input
                        type="number"
                        name="Price"
                        className="bid-item"
                        placeholder="Sessions Price"
                        onKeyDown={(e) => {
                          console.log(e.key, " ,", e.keyCode);
                          if (
                            e.keyCode == 38 ||
                            e.keyCode == 37 ||
                            e.keyCode == 39 ||
                            e.keyCode == 40
                          ) {
                            e.preventDefault();
                          }
                        }}
                        onWheel={(e) => {
                          e.preventDefault();
                        }}
                        value={x.Price}
                        onChange={(e) => handleInputChange(e, i)}
                      />
                      <p>{x.PriceCheck}</p>
                    </div>
                    <div className="valid-desc col-lg-4">
                      <input
                        type="text"
                        name="description"
                        className="bid-item"
                        placeholder="Session Detail"
                        value={x.description}
                        onChange={(e) => handleInputChange(e, i)}
                      />
                      <p>{x.sessionsCheck}</p>
                    </div>
                    <div className="d-flex add-btn">
                      {inputList.length > 1 && (
                        <button
                          type="button"
                          className="ad-sub-btn"
                          onClick={() => handleRemoveClick(i)}
                        >
                          Remove
                        </button>
                      )}
                      {inputList.length - 1 === i && (
                        <button
                          type="button"
                          className="ad-sub-btn"
                          onClick={() => handleAddClick(i)}
                        >
                          Add
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
              <Button
                type="submit"
                className="btn btn-outline-primary custom-btn btn-8 next-btn4 "
                context="Submit"
              />
            </div>
          </form>
        </Modal.Body>
      </Modal>
      {isPaymentDone ||
        (rejecteRequested && (
          <Modal show={isPaymentDone || rejecteRequested}>
            <Loader />
          </Modal>
        ))}
    </section>
  );
}

export default React.memo(Conformation);

// let info = {
//   bidId: res.data.id,
//   counsellorId: item.counselorid._id,
//   userId: userid._id,
//   sessionId: user._id,
//   sessiontitle: user.sessiontitle,
//   skills: user.skills,
//   sessionDes: user.sessionDes,
//   noOfSessions: item.sessionNumber,
//   sessionDetails: item.sessionSlots,
//   ...details,
//   bidReqId: id,
//   userId: { _id: userid._id },
//   amount: amountToBeAdded,
//   currency: user.defaultCurrency,
//   transactionType: "CREDIT",
//       transactionVia: "Razorpay",
//bidId: id._id,
// bidId: id._id,
// amount: amountToBeAdded,
// userId: bidId: id._id,
// sessionId: emp.state,
// userId: userData.userId,
//       sessionId: emp.state,
//       bidCost: Number(0.03 * user.sessionCost),
//       sessionCurrency: user.defaultCurrency,
//       entity: "counsellor",
// };

{
  /* <Modal.Body className="pop">
 <section className="studentprofile-pop ">
  <div className="modal-content  container" id="modalheader">
    <form 
       onSubmit={handleSubmit((data) => {
      //   if (wait) {
      //     return;
      //   }
      //   wait = true;
      //   setTimeout(() => {
      //     wait = false;
      //   }, 5000);
      //   submitData(data);
      // })}
      encType="multipart/form-data"
      className="session-dtl-pop-fnt"
    >
      <div className="modal-body-div">
    <div>
          <p>Skills</p>
        </div> 

     <select>
          <option defaultValue hidden>
            {user.skills}
          </option>
          {skill.map((sk, index) => {
            return (
              <option key={index} value={sk}>
                {sk}
              </option>
            );
          })}
        </select>
        {user.sessionNumber.length > 0 &&
          user.sessionNumber?.map((item, index) => {
            // debugger;
            return (
              <div className="modal-body-div">
                <div key={index}>
                  <div>
                    <p>No of sessions</p>
                  </div>
                  <input
                    key={index}
                    placeholder={item?.sessions}
                    defaultValue={item?.sessions}
                    onKeyPress={(e) => {
                      if (e.target.value === "" && e.key === " ") {
                        e.preventDefault();
                      }
                    }}
                    {...register(`noOfSessions${index}`, {
                      required: true,
                      maxLength: 2,
                      pattern: "",
                    })}
                  />
                  {errors?.noOfSessions?.type === "required" && (
                    <p className="valid-prof">
                      This field is required
                    </p>
                  )}

                  {errors?.noOfSessions?.type === "maxLength" && (
                    <p className="valid-prof">Limit your sessions</p>
                  )}
                </div>
                <div className="modal-body-div">
                  <div>
                    <p>Price</p>
                  </div>

                  <input
                    key={index}
                    placeholder={item?.Price}
                    defaultValue={item?.Price}
                    onKeyPress={(e) => {
                      if (e.target.value === "" && e.key === " ") {
                        e.preventDefault();
                      }
                    }}
                    {...register(`price${index}`, {
                      required: true,
                      maxLength: 5,
                    })}
                  />
                  {errors?.price?.type === "required" && (
                    <p className="valid-prof">
                      This field is required
                    </p>
                  )}

                  {errors?.price?.type === "maxLength" && (
                    <p className="valid-prof">Limit your charges</p>
                  )}
                </div>
              </div>
            );
          })}
      {user.sessionNumber?.map(
          (item, index) => (
            console.log("item", item.sessions),
            (
              <div>
                <div className="modal-body-div">
                  <input
                    placeholder={item.sessions}
                    defaultValue={item.sessions}
                    onKeyPress={(e) => {
                      if (e.target.value === "" && e.key === " ") {
                        e.preventDefault();
                      }
                    }}
                    {...register("noOfSessions", {
                      required: true,
                      maxLength: 2,
                      pattern: "",
                    })}
                  />
                  {errors?.noOfSessions?.type === "required" && (
                    <p className="valid-prof">
                      This field is required
                    </p>
                  )}

                  {errors?.noOfSessions?.type === "maxLength" && (
                    <p className="valid-prof">Limit your sessions</p>
                  )}
                </div>
                <div className="modal-body-div">
                  <input
                    // key={index}
                    placeholder={item.Price}
                    defaultValue={item.Price}
                    onKeyPress={(e) => {
                      if (e.target.value === "" && e.key === " ") {
                        e.preventDefault();
                      }
                    }}
                    {...register("price", {
                      required: true,
                      maxLength: 5,
                    })}
                  />
                  {errors?.price?.type === "required" && (
                    <p className="valid-prof">
                      This field is required
                    </p>
                  )}

                  {errors?.price?.type === "maxLength" && (
                    <p className="valid-prof">Limit your charges</p>
                  )}
                </div>
              </div>
            )
          )
        )} 
        <div className="modal-body-div">
          <div>
            <p>Session Description</p>
          </div>
          <input
            type="text" // Change type to "text" since you want to allow the plus sign
            defaultValue={
              user?.sessionDes?.charAt(0).toUpperCase() +
              user?.sessionDes?.slice(1)
            }
            placeholder="Session Description"
            {...register("desc", {
              required: true,
            })}
          />
          {errors?.desc?.type === "required" && (
            <p className="valid-prof">This field is required</p>
          )}
          {/* <select onChange={(e) => handleSkills(e)}> */
}
// </div>
// </div>
// <div className="submit-btn text-center mb-2 mt-2">
//   <button
//     className=" btn btn-submit"
// disabled={isDisabled}
// >
//           Submit
//         </button>
//       </div>
//     </form>
//   </div>
// </section>
// </Modal.Body> */}
