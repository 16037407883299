import React, { useState, useRef, useEffect } from "react";
import { Dropdown, Form } from "react-bootstrap";
import axios from "axios";
import { API } from "../../api-config";
import { Rating } from "react-simple-star-rating";
import "../../style/partials/components/_SearchSection.scss";
import Button from "react-bootstrap/Button";
// import e from "cors";

let selectedCategory = [];
let selectedSubCategory = [];
let selectedSkills = [];
let selectedRatings = [];
const ratings = [3, 4];
let ratingDem = null;
const SearchFilter = (props) => {
  const [cats, setCats] = useState([]);
  const [subCateg, setSubCateg] = useState([]);
  const [allsubCats, setallsubCats] = useState([]);
  const [selectedCats, setSelectedCats] = useState([]);
  const [skillsLists, setSkillsLists] = useState([]);
  const [results, setResult] = useState();
  const [categoriesAll, setCategoriesAll] = useState([]);
  const [skillsAll, setSkillsAll] = useState([]);
  const [subCategoriesAll, setSubCategoriesAll] = useState([]);
  const [ratingdata, setRatingdata] = useState([]);
  const [updateDom, setUpdateDom] = useState(1);
  const [changeSearch, setChangeSearch] = useState(false);
  useEffect(() => {
    console.log("PROPS", props);
    if (props.categories.length > 0) {
      setCats((prevState) => props.categories[0]);
      setCategoriesAll((prevState) => props.categories[0]);
      setSkillsAll((prevState) => props.categories[2]);
      setSubCategoriesAll((prevState) => props.categories[1]);
      setSubCateg((prevState) => props.categories[1]);
      setSkillsLists((prevState) => props.categories[2]);
    }
  }, []);
  // console.log("PROPS", props);
  const searchRef = useRef();
  // const loadSessions = async () => {
  //   const response = await axios.get(`${API}/api/session/filterSessions`, {
  //     params: {
  //       category: selectedCategory,
  //       subcategory: selectedSubCategory,
  //       skill: selectedSkills,
  //     },
  //   });
  // };
  // const fetchCategories = async () => {
  //   const response = await axios.get(`${API}/api/categories/allCat`);

  //   // setAllCategories(response.data);
  // };

  // const handleSelect = (selectedList) => {
  //   setSelectedCats(selectedList);
  // };
  // const handleRemove = (selectedList) => {
  //   setSelectedCats(selectedList);
  // };
  // const ratingData = async () => {
  //   const counsellorrating = await axios.get(`${API}/api/rating/get-rating`);
  //   console.log("PASS 1");
  //   setRatingdata(counsellorrating.data);
  //   const categoriesResponse = await axios.get(`${API}/api/categories/allCat`);
  //   console.log("PASS 2");
  //   // setCats(categoriesResponse.data);
  //   const subCategoriesResponse = await axios.get(
  //     `${API}/api/subcateg/all-subCat`
  //   );
  //   console.log("PASS 3");
  //   setallsubCats(subCategoriesResponse.data);
  //   // console.log(emp.state);
  //   // console.log("PASS 4");
  //   // const results = await axios.get(`${API}/api/session/filterSessions`, {
  //   //   headers: { skill: emp.state, cskill: "AI Websites" },
  //   // });
  //   console.log("EENNNETTER", results);
  // };
  const handleCategory = (id) => {
    if (selectedCategory.includes(id)) {
      selectedCategory = selectedCategory.filter((item) => item != id);
      if (selectedCategory.length > 0) {
        setSubCateg((prevState) =>
          subCategoriesAll.filter((item) =>
            selectedCategory.includes(item.categories)
          )
        );
        selectedSubCategory = selectedSubCategory.filter((ids) =>
          subCateg.some((item) => item._id == ids)
        );
        setSkillsLists((prevState) =>
          skillsAll.filter((item) => {
            console.log(selectedCategory.includes(item.category));
            return true;
          })
        );
        selectedSkills = selectedSkills.filter((ids) =>
          skillsLists.some((item) => item._id == ids)
        );
      } else {
        selectedSkills = [];
        selectedSubCategory = [];
        setSubCateg((prevState) => subCategoriesAll);
        setSkillsLists((prevState) => skillsAll);
        setUpdateDom((prevState) => prevState + 1);
      }
      props.handleSearchCounsellor([
        selectedCategory,
        selectedSubCategory,
        selectedSkills,
        selectedRatings,
        searchRef.current.value.trim().length > 0
          ? searchRef.current.value.split(" ").length > 0
          : [],
      ]);
    } else {
      //   console.log("allsubCats", allsubCats, "ID", id);
      selectedCategory.push(id);
      console.log("allsubCats", allsubCats, "ID", id, selectedCategory);
      setSubCateg((prevState) =>
        subCategoriesAll.filter((item) =>
          selectedCategory.includes(item.categories)
        )
      );
      setSkillsLists((prevState) =>
        skillsAll.filter((elee) => selectedCategory.includes(elee.category))
      );
    }

    props.handleSearchCounsellor([
      selectedCategory,
      selectedSubCategory,
      selectedSkills,
      selectedRatings,
      searchRef.current.value.trim().length > 0
        ? searchRef.current.value.split(" ")
        : [],
    ]);
    setUpdateDom((prevState) => prevState + 1);
  };
  const handleSubCategory = (id) => {
    // debugger;
    if (selectedSubCategory.includes(id)) {
      selectedSubCategory = selectedSubCategory.filter((item) => item != id);
      // if (selectedSubCategory.length <= 0) {
      //   setSkillsLists((prevState) =>
      //     skillsAll.filter((item) =>
      //       selectedCategory.includes(item.category[0])
      //     )
      //   );
      // }

      // selectedSkills = selectedSkills.filter((item) =>
      //   skillsAll.find((it) => it._id == item)
      // );
      props.handleSearchCounsellor([
        selectedCategory,
        selectedSubCategory,
        selectedSkills,
        selectedRatings,
        searchRef.current.value.trim().length > 0
          ? searchRef.current.value.split(" ")
          : [],
      ]);
    } else {
      selectedSubCategory.push(id);
      // let catId = subCategoriesAll.find((it) => it._id == id);
      // console.log(catId);
      // if (!selectedCategory.includes(catId.categories)) {
      //   // handleCategory(catId.categories);
      //   selectedCategory.push(catId.categories);
      // }
      // let allSkills = skillsAll.filter((item) =>
      //   selectedSubCategory.includes(item.subcategory[0])
      // );
      // setSkillsLists((prevState) => allSkills);
      // selectedSkills = selectedSkills.filter((item) =>
      //   allSkills.find((it) => it._id == item)
      // );
      // const sub = allsubCats.filter((item) => item.categories == id);
      // setSubCateg((prevState) => [...prevState, ...sub]);
      // axios
      //   .get(`${API}/api/skills/getSkills`, {
      //     params: { subcat: selectedSubCategory },
      //   })
      //   .then((response) => {
      //     console.log("RREESSPPOONNSEE", response);
      //     setSkillsLists(response.data);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
      // loadSessions();
      props.handleSearchCounsellor([
        selectedCategory,
        selectedSubCategory,
        selectedSkills,
        selectedRatings,
        searchRef.current.value.trim().length > 0
          ? searchRef.current.value.split(" ")
          : [],
      ]);
      setUpdateDom((prevState) => prevState + 1);
    }
  };
  const handleSkills = (item) => {
    console.log("SELECTED SKILLS & FILTERS", selectedSkills, item);
    // debugger;
    if (!selectedSkills.includes(item._id)) {
      selectedSkills.push(item._id);
      // let skill = skillsAll.find((ite) => ite._id == item._id);
      // if (
      //   !selectedSubCategory.includes(item.subcategory[0]) &&
      //   !selectedCategory.includes(item.category[0])
      // ) {
      //   if (!selectedCategory.includes(item.category[0])) {
      //     selectedCategory.push(item.category[0]);
      //   }
      //   if (!selectedSubCategory.includes(item.subcategory[0])) {
      //     selectedSubCategory.push(item.subcategory[0]);
      //   }
      // } else if (
      //   !selectedSubCategory.includes(item.subcategory[0]) &&
      //   selectedCategory.includes(item.category[0])
      // ) {
      //   handleSubCategory(skill.subcategory[0]);
      // }
      // if (
      //   !selectedSubCategory.includes(item.subcategory[0]) &&
      //   !selectedCategory.includes(item.category[0])
      // ) {
      //   selectedCategory.push(item.category[0]);
      //   selectedSubCategory.push(item.subcategory[0]);
      // } else if (
      //   !selectedSubCategory.includes(item.subcategory[0]) &&
      //   selectedCategory.includes(item.category[0])
      // ) {
      //   selectedSubCategory.push(item.subcategory[0]);
      // }
      // if (!selectedCategory.includes(...item.category)) {
      //   handleCategory(item?.category[0]);
      // }
      // console.log(selectedSkills);
      // const filterCounsellors = async () => {
      //   const results = await axios.get(
      //     `${API}/api/session/filter-Counsellors`,
      //     {
      //       headers: { skill: selectedSkills },
      //     }
      //   );
      // };
      // loadSessions();
      // console.log("SELECTED SKILLSSS", selectedSkills);
      props.handleSearchCounsellor([
        selectedCategory,
        selectedSubCategory,
        selectedSkills,
        selectedRatings,
        searchRef.current.value.trim().length > 0
          ? searchRef.current.value.split(" ")
          : [],
      ]);
      console.log(selectedCategory, selectedSubCategory, selectedSkills);
    } else {
      // console.log("BEFORE EXCLUDING", selectedSkills);
      selectedSkills = selectedSkills.filter((ele) => ele != item._id);
      // console.log("AFTER EXCLUDING", selectedSkills);
      // if (selectedSkills.length <= 0) {
      //   setSubCateg((prevState) =>
      //     subCategoriesAll.filter((item) =>
      //       selectedCategory.includes(item.categories)
      //     )
      //   );
      // }
      console.log("REMOVING");
      props.handleSearchCounsellor([
        selectedCategory,
        selectedSubCategory,
        selectedSkills,
        selectedRatings,
        searchRef.current.value.trim().length > 0
          ? searchRef.current.value.split(" ")
          : [],
      ]);
    }
    setUpdateDom((prevState) => prevState + 1);
  };
  const handleReviews = (id) => {
    console.log("RATINGS", id);
    if (selectedRatings.includes(id)) {
      console.log("ALREADY SELECTED BEFORE ", selectedRatings);
      selectedRatings = selectedRatings.filter((ind) => id != ind);
      props.handleSearchCounsellor([
        selectedCategory,
        selectedSubCategory,
        selectedSkills,
        selectedRatings,
        searchRef.current.value.trim().length > 0
          ? searchRef.current.value.split(" ")
          : [],
      ]);
    } else {
      selectedRatings.push(id);
      console.log("AFTER SELECTING ", selectedRatings);
      console.log("RATINGS2", id);
      props.handleSearchCounsellor([
        selectedCategory,
        selectedSubCategory,
        selectedSkills,
        selectedRatings,
        searchRef.current.value.trim().length > 0
          ? searchRef.current.value.split(" ")
          : [],
      ]);
    }
  };
  const handleSearch = async (event) => {
    try {
      props.handleSearchCounsellor([
        selectedCategory,
        selectedSubCategory,
        selectedSkills,
        selectedRatings,
        searchRef.current.value.trim().split(" "),
      ]);
    } catch (error) {
      console.log("ERROR ", error);
    }
  };

  return (
    <div className="container">
      <div className="col-md-3 d-flex">
        <div className="d-flex m-1 mt-3 " style={{ height: "45px" }}>
          <input
            placeholder="Search counsellor"
            id="search"
            ref={searchRef}
            onKeyPress={(event) => {
              if (event.key == "enter") {
                handleSearch(event);
              }
            }}
            onChange={() => {
              handleSearch();
            }}
          />
          <Button
            className="drop-btn-costmize m-1"
            onClick={(event) => {
              handleSearch(event);
            }}
          >
            Search
          </Button>
        </div>
        {/* <div>Filter :</div> */}

        <div className="skills mt-2">
          <Dropdown>
            <Dropdown.Toggle className="drop-btn-costmize " id="dropdown-basic">
              Category
            </Dropdown.Toggle>
            {cats && (
              <Dropdown.Menu className="drop-scroll-costmaize select-categry-sarch-bar">
                <input
                  onChange={(e) => {
                    console.log(e.target.value, " THESE ARE CATS", cats);
                    let pattern = new RegExp(`${e.target.value}`, "i");
                    setCats(() => {
                      const aal = categoriesAll.filter((item) =>
                        pattern.test(item.category)
                      );
                      console.log("CCAATTAALLL", aal);
                      return aal.length > 0 ? aal : [];
                    });
                  }}
                ></input>
                {cats &&
                  cats.map((item, index) => (
                    <div className="drop-costmize " key={index}>
                      <input
                        type="checkbox"
                        onClick={() => {
                          handleCategory(item._id);
                        }}
                        checked={selectedCategory.includes(item._id)}
                      />
                      <label>{item.category}</label>
                    </div>
                  ))}
              </Dropdown.Menu>
            )}
          </Dropdown>
        </div>

        <div className="skills mt-2">
          <Dropdown>
            <Dropdown.Toggle className="drop-btn-costmize" id="dropdown-basic">
              Sub Category
            </Dropdown.Toggle>
            {updateDom && (
              <Dropdown.Menu className="drop-scroll-costmaize select-categry-sarch-bar">
                <input
                  onChange={(e) => {
                    console.log(e.target.value);
                    let pattern = new RegExp(`${e.target.value}`, "i");
                    setSubCateg(() =>
                      subCategoriesAll.filter((item) => pattern.test(item.name))
                    );
                  }}
                />
                {subCateg.map((item, index) => (
                  <div className="drop-costmize" key={index}>
                    <input
                      type="checkbox"
                      onClick={() => {
                        handleSubCategory(item._id);
                      }}
                      checked={selectedSubCategory.includes(item._id)}
                    />
                    <label>{item.name}</label>
                  </div>
                ))}
              </Dropdown.Menu>
            )}
          </Dropdown>
        </div>
        <div className="skills mt-2">
          <Dropdown>
            <Dropdown.Toggle className="drop-btn-costmize" id="dropdown-basic">
              Skills
            </Dropdown.Toggle>
            {updateDom && (
              <Dropdown.Menu className="drop-scroll-costmaize select-categry-sarch-bar">
                <input
                  onChange={(e) => {
                    let val = e.target.value.trim();
                    if (val == "") {
                      setSkillsLists(skillsAll);
                      return;
                    }
                    let pattern = new RegExp(`${e.target.value}`, "i");
                    setSkillsLists(() =>
                      skillsAll.filter((item) => pattern.test(item.skill))
                    );
                  }}
                ></input>
                {skillsLists.map((item, index) => (
                  <div className="drop-costmize">
                    <input
                      type="checkbox"
                      onClick={() => {
                        handleSkills(item);
                      }}
                      // checked={() => {
                      //   return selectedSkills.includes(item._id);
                      // }}
                    />
                    <label>{item.skill}</label>
                  </div>
                ))}
              </Dropdown.Menu>
            )}
          </Dropdown>
        </div>
        <div className="reviews mt-2">
          <Dropdown>
            <Dropdown.Toggle className="drop-btn-costmize" id="dropdown-basic">
              Reviews
            </Dropdown.Toggle>
            {updateDom && (
              <Dropdown.Menu className="drop-scroll-costmaize select-categry-sarch-bar">
                {ratings.map((item, index) => (
                  <div scroll="true">
                    <input
                      type="checkbox"
                      onClick={() => {
                        console.log(item);
                        handleReviews(item);
                      }}
                    />
                    <Rating initialValue={item} readonly={true}></Rating>
                    <label>{`& Above`}</label>
                  </div>
                ))}
              </Dropdown.Menu>
            )}
          </Dropdown>
        </div>
        {/* <div className="search mt-2">
                        <input type="text" placeholder="Search.." name="search" />
                    </div> */}
        {/* <div className="price mt-2">
                        <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic">
                            Budget
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <label>$FROM</label>
                            <input type="number" />
                            <label>$TO</label> <input type="number" />
                        </Dropdown.Menu>
                        </Dropdown>
                    </div> */}
      </div>
    </div>
  );
};
export default React.memo(SearchFilter);
