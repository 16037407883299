/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext, useEffect, useRef } from "react";
import counsellormenu from "..//../../data/counsellormenu";
import { HiMenu } from "react-icons/hi";
import { CgProfile } from "react-icons/cg";
import { FiSearch, FiLogOut } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";
import { IoMdNotificationsOutline } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../Header/Logo";
import "..//../..//style/partials/components/_header.scss";
import UserContext from "../../../contexts/UserContext";
import { Dropdown } from "react-bootstrap";
import { API } from "../../../api-config";
import axios from "axios";
import socket from "../../../socket/socketio";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";

let screenWidth = window.innerWidth;

function CounsellorNavbar() {
  let notice = "";
  let oneEffect = true;
  const navigate = useNavigate();
  const { userData, setUserData } = useContext(UserContext);
  const [activePro, setActiveProf] = useState(false);
  const [active, setActive] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  let notiRef = useRef();
  let notiBtn = useRef();
  // console.log(userData);
  useEffect(() => {
    const noti = document.querySelector(".noti");
    let handler = (e) => {
      if (
        !notiRef.current.contains(e.target) &&
        !notiBtn.current.contains(e.target)
      ) {
        noti.classList.remove("display");
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);
  const handleClick = () => {
    if (window.innerWidth > 991) return;
    setActive(!active);
  };
  const handleclose = () => {
    setActive(false);
  };
  // useEffect(() => {}, [window.addEventListener]);
  const notificationLoad = async () => {
    const response = await axios.post(
      `${API}/api/notifications/getNotification`,
      {
        userId: userData.userId,
        limit: 15,
        offset: 0,
      },
      {
        headers: {
          token: userData.token,
        },
      }
    );
    setNotifications(response.data.note[0].notification);
    response.data.note[0].notification.map((item) => {
      if (item.date > response.data.note[0].lastSeen) {
        setNotificationCount((prevState) => prevState + 1);
      }
    });
  };
  useEffect(() => {
    if (!oneEffect) return;
    oneEffect = false;
    setNotificationCount((prevState) => prevState - prevState);
    notificationLoad();
    socket.on(`notig${userData.userId}`, (msg) => {
      setNotificationCount((prevState) => prevState - prevState);
      notificationLoad();
      // console.log("NEW NOTIFICATION");
      alertify.success("New Notification");
    });
    socket.on(`paymentRecieved${userData.userId}`, () => {
      setNotificationCount((prevState) => prevState - prevState);
      notificationLoad();
      alertify.success("Payment Recieved");
    });
    //Uncommenting this cleanup causing loss of notifications
    // return () => {
    //   socket.off(`notig${userData.userId}`);
    // };
  }, [socket]);
  const notifiy = (notice) => {
    setNotifications((prevState) => [...prevState, { msg: notice }]);
    // console.log(notifications);
  };

  const handleNotification = async () => {
    try {
      // console.log(notifications);
      const noti = document.querySelector(".noti");
      noti.classList.toggle("display");
      setNotificationCount(0);
      const response = await axios.post(
        `${API}/api/notifications/seenNotification`,
        { userId: userData.userId },
        {
          headers: {
            token: userData.token,
          },
        }
      );
    } catch (error) {
      console.log(error);
    }
  };
  const handleExpand = () => {
    const search = document.querySelector(".search-input");
    search.classList.toggle("search-expanded");
  };
  const LogOut = () => {
    localStorage.removeItem("userData");
    localStorage.clear("userData");
    setUserData(null);
    navigate("/Login");
  };
  return (
    <div>
      <nav className="navbar container-fluid">
        <div className="col-lg-11 col-md-10 col-sm-10 col-9 d-flex-costomize">
          <div
            className="menu-icon col-lg-11 col-md-6 col-6"
            onClick={handleClick}
          >
            <div className="menu-list nav-link-costomize">
              {counsellormenu.map((item, index) => {
                // console.log("ITEEMM", item.title);
                return (
                  <Link
                    key={index}
                    to={item.url}
                    className={item.cName}
                    onClick={handleclose}
                  >
                    {item.title}
                  </Link>
                );
              })}
            </div>
            <i className={active ? "fas fa-times" : "fas fa-bars"}></i>
          </div>
          <Logo />
          <ul className={active ? "nav-menu active" : "nav-menu"}>
            <li className="header-logo-sticky">
              <h3>A2Z</h3>
              <a href="#" className="fas fa-times" onClick={handleclose}>
                <AiOutlineClose />
              </a>
            </li>
            {counsellormenu.map((item, index) => {
              return (
                <li key={index}>
                  <Link
                    to={item.url}
                    className={item.cName}
                    onClick={handleclose}
                  >
                    {item.title}
                  </Link>
                </li>
              );
            })}
            {/* <li>
              <Sub />
            </li> */}
          </ul>
        </div>
        <div
          className="  col-md-2 col-sm-2 col-lg-1 col-3 hd-flex tech"
          key="mdd"
        >
          <div
            className="dropdown col-2"
            ref={notiBtn}
            onClick={handleNotification}
            key="mdd1"
          >
            <button className="dropbtn noti-x2">
              <IoMdNotificationsOutline />
              {notificationCount != 0 && (
                <span className="noti-num">{notificationCount}</span>
              )}
            </button>
          </div>
          <Dropdown>
            <Dropdown.Toggle className="admin-icon">
              <CgProfile />
            </Dropdown.Toggle>
            <Dropdown.Menu className="drop-scroll-costmaize open-dropdwon-box-admin-icon">
              {/* <div style={{ position: "absolute", zIndex: "999" }}> */}
              <Dropdown.Item>
                <div className="dropdown col-md-2" key="mdd11">
                  <button className="dropbtn" onClick={LogOut}>
                    Logout
                    <FiLogOut />
                  </button>
                </div>
              </Dropdown.Item>
              <Dropdown.Item>
                <div className="dropdown col-md-2" key="mdd11">
                  <button
                    onClick={() => {
                      navigate("/Counsellor-profile");
                    }}
                  >
                    My Profile
                  </button>
                </div>
              </Dropdown.Item>
              {/* </div> */}
            </Dropdown.Menu>
          </Dropdown>
          {/* <div style={{ position: "relative" }}>
            <button
              className="dropbtn"
              onClick={() => {
                setActiveProf((prevState) => !prevState);
              }}
            >
              <CgProfile />
            </button>
            {activePro && (
              <div style={{ position: "absolute", zIndex: "999" }}>
                <div className="dropdown col-md-2" key="mdd11">
                  <button className="dropbtn" onClick={LogOut}>
                    <FiLogOut />
                  </button>
                </div>
                <div className="dropdown col-md-2" key="mdd11">
                  <button
                    onClick={() => {
                      navigate("/Counsellor-profile");
                    }}
                  >
                    My Profile
                  </button>
                </div>
              </div>
            )}
          </div> */}
        </div>
      </nav>

      <div className="noti" ref={notiRef} key="mdd21">
        {notifications.length > 0 ? (
          notifications.map((item, index) => <p key={index}>{item.msg}</p>)
        ) : (
          <div>All Caught Up</div>
        )}
        {notifications.length > 0 && (
          <div className="det-button mt-3">
            <button
              type="button"
              className="btn btn-outline-primary custom-btn btn-8"
              onClick={() => {
                navigate("/counseleeNotification");
              }}
            >
              See More
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
export default React.memo(CounsellorNavbar);
