import { useContext, useEffect, useState } from "react";
import UserContext from "../../../contexts/UserContext";
import SectionsHead from "../../Home/Heading";
// import "../../../style/partials/Counsellor/_dashboard.scss";
import "../../../style/partials/Counsellor/counsellor-all-pages.scss"

const Earnings = (props) => {
  const { userData } = useContext(UserContext);
  const [wallets, setWallets] = useState([]);
  console.log(props);
  useEffect(() => {
    setWallets((prevState) => props.userdetails);
  }, [props.userdetails]);

  //   console.log(wallets);
  return (
    <div className="container pt-5 pb-5">
      <SectionsHead heading="Balance" />
      {wallets && (
        <div className="row pt-4 pb-2 p-2 m-2 m-sm-0 shadow rounded border-dark d-flex justify-content-center align-items-center">
          {wallets.map((item, index) => (
            <div className="mb-3  col-md-6   col-lg-4 col-sm-12" key={index}>
              <div className="rounded balance-card shadow-sm" key={index}>
                <div className="d-flex p-2 pr-3 pl-3 justify-content-between">
                  <label>
                    Currency
                  </label>
                  <span>
                    {item.currencyCode}
                  </span>
                </div>
                <div className="d-flex p-2 pr-3 pl-3 balance justify-content-between">
                  <label>
                    Balance
                  </label>
                  <span>{item.balance}</span>
                </div>
                <div className="d-flex p-2 pr-3 pl-3 justify-content-between">
                  <label>
                    Last Updated
                  </label>

                  <span>
                    {new Date(item.createdAt).toString().slice(4, 15)}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
export default Earnings;
