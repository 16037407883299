import { React, useContext } from "react";
import { Link } from "react-router-dom";
import "../..//style/partials/components/_logo.scss";
import UserContext from "../../contexts/UserContext";

function Logo() {
  const { userData } = useContext(UserContext);

  return (
    <>
      {userData === null ? (
        <h1 className="navbar-logo">
          <Link to="/">A2Z</Link>
        </h1>
      ) : userData.flag === "STUDENT" ? (
        <h1 className="navbar-logo">
          <Link to="/Students">A2Z</Link>
        </h1>
      ) : userData.flag === "COUNSELLOR" ? (
        <div className="logo-div-1 col-md-7 col-lg-1">
          <h1 className="navbar-logo">
            <Link to="/Counsellor">A2Z</Link>
          </h1>
        </div>
      ) : (
        <h1 className="navbar-logo">
          <Link to="/">A2Z</Link>
        </h1>
      )}
    </>
  );
}

export default Logo;
