import React, { useState, useEffect, useContext } from "react";
import "..//..//../style/partials/Counsellor/_counsellorsession.scss";
import { AiFillMinusCircle, AiFillPlusCircle } from "react-icons/ai";
import UserContext from "../../../contexts/UserContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API } from "../../../api-config";
import $ from "jquery";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import { useForm } from "react-hook-form";
import { throttle } from "lodash";
import socket from "../../../socket/socketio";
import SectionsHead from "../../Home/Heading";
import Button from "../../Elements/Button";
// let Tot = 0;
let selectedSkill = [];
let waitAdd = false;
let pricePattern = /[0]*/;
function Counsellosession() {
  const {
    register,
    formState: { errors },
    trigger,
    handleSubmit,
    clearErrors,
  } = useForm({
    mode: "onChange",
  });
  const navigate = useNavigate();
  const { userData } = useContext(UserContext);
  const [inputList, SetInputList] = useState([
    { sessions: `Slot ${1}`, Price: 0, description: "" },
  ]);
  // console.log("userData", userData);
  const [selected, SetSelected] = useState([]);
  const [skill, Setskill] = useState([]);
  const [sessiontitle, Setsessiontitle] = useState("");
  const [sessiondes, Setsessiondes] = useState("");
  const [isDisabled, setDisabled] = useState(false);
  const [Total, setTotal] = useState(0);
  const [sessionSlots, setSessionSlots] = useState(1);

  useEffect(() => {
    $(".next-btn1").click(() => {
      var title = $("#ses-title").val();
      $(".validation").remove();
      if (title) {
        $(".form-sec-2").show();
        $(".next-btn1").hide();
      }
    });
    $(".next-btn2").click(() => {
      var description = $("#ses-des").val();
      $(".validation").remove();
      if (description) {
        $(".form-sec-3").show();
        $(".next-btn2").hide();
      }
    });
    $(".next-btn3").click(() => {
      $(".validation").remove();
      $("input[name='item']").change(function () {
        $(".validation").remove();
      });
      if (selectedSkill.length > 0) {
        $(".form-sec-4").show();
        $(".next-btn3").hide();
      }
    });
  }, []);

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    SetInputList(list);
  };

  const formValidation = (inputList) => {
    try {
      const data = [...inputList];
      let validSess = true;
      let validPrice = true;
      let validDesc = true;
      for (let index = 0; index < data.length; index++) {
        if (
          data[index].Price === "" ||
          data[index].Price < 500 ||
          pricePattern.test(data[index].Price)
        ) {
          data[index].PriceCheck = "Enter valid price";
          validPrice = false;
        } else {
          data[index].PriceCheck = "";
          validPrice = true;
        }
        if (data[index].Price < 500) {
          data[index].PriceCheck = "Price should be above 499";
          validPrice = false;
        } else {
          data[index].PriceCheck = "";
          validPrice = true;
        }
        if (data[index].description == "") {
          data[index].sessionsCheck = " Description required";
          validDesc = false;
        }
      }
      SetInputList(data);
      return validSess && validPrice && validDesc;
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    SetInputList(list);
    setSessionSlots((prevState) => prevState - 1);
    let test = Total - inputList[index].Price;
    setTotal((prevState) => prevState - inputList[index].Price);
    setTotal((prevState) => {
      console.log(prevState);
      return prevState;
    });
    // let Tots = 0;
    // inputList.map((item) => {
    //   console.log("item.price", item.Price);
    //   Tots -= Number(item.Price);
    //   console.log("tot", Tots);
    // });
  };

  const handleAddClick = (i) => {
    const errorRes = formValidation(inputList);
    if (errorRes) {
      SetInputList([...inputList, { sessions: `Slot ${i + 2}`, Price: "" }]);
    } else {
      console.log("test");
    }
    setSessionSlots((prevState) => prevState + 1);
  };

  //could handle the async issue by nested async method inside sync submit method
  const submitData = (e) => {
    const errorRes = formValidation(inputList);
    if (errorRes) {
      console.log("ALL SLOTS  ", sessionSlots);
      console.log("TOTAL CHARGES  ", userData);
      let Tot = 0;
      inputList.map((item) => {
        console.log("Item Price", item.Price);
        Tot += Number(item.Price);
        console.log("tot", Tot);
      });
      try {
        axios
          .post(
            `${API}/api/session/create-session`,
            {
              counsellorId: userData.userId,
              sessiontitle: e.sessiontitle,
              skills: selectedSkill,
              sessionNumber: inputList,
              sessionDes: e.sessiondes,
              sessionCost: Tot,
              defaultCurrency: userData.defaultCurrency,
              slotCount: sessionSlots,
            },
            {
              headers: {
                token: userData.token,
              },
            }
          )
          .then((res) => {
            console.log(res);
            socket.emit("newSession");
            navigate("/Counsellor");
          });
      } catch (err) {
        console.log(err);
        alertify.error(err.response.data.status);
      }
    } else {
      alertify.error("Enter complete details");
    }
  };
  const throttleSubmitData = throttle(submitData, 2000);
  const updateUsers = async () => {
    let myData = await axios.post(
      `${API}/api/counselor-profile/get-counselor-skill`,
      {
        userId: userData.userId,
      }
    );
    // console.log("myData.data", myData.data);
    Setskill(myData.data);
  };
  useEffect(() => {
    if (!userData.isProfile) {
      navigate("/Counsellor");
    }
    updateUsers();
  }, [userData.userId]);

  return (
    <div className="container">
      <SectionsHead heading="Post New Session" />
      <div className=" new-session mt-4 mb-4">
        <form
          onSubmit={handleSubmit((data) => {
            if (waitAdd) {
              return;
            }
            waitAdd = true;
            setTimeout(() => {
              waitAdd = false;
            }, 4000);
            // handleSubmit(submitData);
            throttleSubmitData(data);
          })}
        >
          <div className="form-sec-1">
            <label className="session-label mt-2" id="session-title">
              Write a title of your session?
            </label>
            <input
              type="text"
              onKeyPress={(e) => {
                if (e.target.value === "" && e.key === " ") {
                  e.preventDefault();
                }
              }}
              {...register("sessiontitle", { required: true })}
              id="ses-title"
            />
            {errors.sessiontitle && (
              <p className="valid-prof">This field is Required</p>
            )}

            <label className="next-btn1 next-btn">
              {" "}
              <input
                type="button"
                className=" mt-3"
                onClick={() => {
                  trigger(["sessiontitle"]);
                }}
              />
              NEXT
            </label>
          </div>
          <div className="form-sec-2">
            <label className="session-label mt-2" id="session-title">
              Write Description of your session?
            </label>
            <textarea
              {...register("sessiondes", { required: true })}
              type="text"
              onKeyPress={(e) => {
                if (e.target.value === "" && e.key === " ") {
                  e.preventDefault();
                }
              }}
              className="mt-2"
              id="ses-des"
            />
            {errors.sessiondes && (
              <p className="valid-prof">This field is Required</p>
            )}

            <label className="next-btn2 next-btn">
              {" "}
              <input
                type="button"
                className=" mt-3"
                onClick={() => {
                  trigger(["sessiondes"]);
                }}
              />
              NEXT
            </label>
          </div>
          <div className="form-sec-3">
            <label className="session-label mt-2" id="session-skill">
              Select your skills for the session?
              <br></br>
              {selected && selected
                ? selected.map((item) => {
                    console.log(item);
                    return <label id="result">{item}</label>;
                  })
                : null}
            </label>
            <h6 className="session-label mt-2">Your Skills</h6>
            <div>
              <div className="check-skill d-flex">
                <div>
                  {skill.skills?.map((item, index) => {
                    return (
                      <label key={`${item}${index}`}>
                        <input
                          type="radio"
                          {...register("skills", { required: true })}
                          id={item}
                          key={item.id}
                          checked={selected.includes(item.skill)}
                          onClick={() => {
                            if (selectedSkill.includes(item._id)) {
                              console.log("ENTERED LISTED");
                              selectedSkill.splice(
                                selectedSkill.indexOf(item._id),
                                1
                              );
                              SetSelected(() => {
                                return selected.filter(
                                  (element) => element != item.skill
                                );
                              });
                              console.log(selectedSkill, selected);
                            } else {
                              console.log("ENTERED NON-LISTED");
                              selectedSkill.push(item._id);
                              SetSelected((prevState) => [
                                ...prevState,
                                item.skill,
                              ]);
                              console.log(selectedSkill, selected);
                            }
                          }}
                        />
                        {item.skill}
                      </label>
                    );
                  })}
                  {errors?.skills?.type === "required" && (
                    <p className="valid-prof">Please Select Session</p>
                  )}
                  {/* <p>vale:{selected}</p> */}
                </div>
              </div>
            </div>
            <label className="next-btn3 next-btn">
              <input
                type="button"
                className=" mt-3"
                onClick={() => {
                  trigger(["skills"]);
                }}
              />
              NEXT
            </label>
          </div>
          <div className="form-sec-4 det-button">
            <span className="session-label mt-2 text-start-mdfy">
              Estimate the Number and Price of your session
              <span style={{ color: "red" }}>*</span>
            </span>
            {inputList.map((x, i) => {
              return (
                <div className="bid-list row" key={x.id}>
                  <div className="valid-sess col-lg-4">
                    <input
                      type="number"
                      name="sessions"
                      className="bid-item"
                      placeholder={`Slot ${i + 1}`}
                      value={`Slot ${i}`}
                      readOnly
                      onChange={(e) => handleInputChange(e, i)}
                    />
                  </div>
                  <div className="valid-sess col-lg-4">
                    <input
                      type="number"
                      name="Price"
                      className="bid-item"
                      placeholder="Sessions Price"
                      onKeyDown={(e) => {
                        console.log(e.key, " ,", e.keyCode);
                        if (
                          e.keyCode == 38 ||
                          e.keyCode == 37 ||
                          e.keyCode == 39 ||
                          e.keyCode == 40
                        ) {
                          e.preventDefault();
                        }
                      }}
                      onWheel={(e) => {
                        e.preventDefault();
                      }}
                      value={x.Price}
                      onChange={(e) => handleInputChange(e, i)}
                    />
                    <p>{x.PriceCheck}</p>
                  </div>
                  <div className="valid-desc col-lg-4">
                    <input
                      type="text"
                      name="description"
                      className="bid-item"
                      placeholder="Session Detail"
                      value={x.description}
                      onChange={(e) => handleInputChange(e, i)}
                    />
                    <p>{x.sessionsCheck}</p>
                  </div>
                  <div className="d-flex add-btn">
                    {inputList.length > 1 && (
                      <button
                        type="button"
                        className="ad-sub-btn"
                        onClick={() => handleRemoveClick(i)}
                      >
                        Remove
                      </button>
                    )}
                    {inputList.length - 1 === i && (
                      <button
                        type="button"
                        className="ad-sub-btn"
                        onClick={() => handleAddClick(i)}
                      >
                        Add
                      </button>
                    )}
                  </div>
                </div>
              );
            })}
            {/* <div className="new-counselling-submit-btn">
            <button className="next-btn4 next-btn"></button>
            </div> */}
            {/* <div className="det-button mt-3"> */}
            <Button
              type="submit"
              className="btn btn-outline-primary custom-btn btn-8 next-btn4 "
              context="Submit"
            />

            {/* </div> */}
          </div>
        </form>
      </div>
    </div>
  );
}
export default React.memo(Counsellosession);
