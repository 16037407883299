const menuItems = [
  {
    title: "Find Skills",
    url: "/topskills",
    cName: "nav-links",
  },
  {
    title: "Find Counsellors",
    url: "/",
    cName: "nav-links",
  },
  {
    title: "Why A2Z",
    url: "/whya2z",
    cName: "nav-links",
  },
  {
    title: "About A2Z",
    url: "/Aboutus",
    cName: "nav-links",
  },
  {
    title: "FAQs",
    url: "/faqs",
    cName: "nav-links",
  },
  {
    title: "Contact US",
    url: "/Contactus",
    cName: "nav-links",
  },
];

export default menuItems;
