import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaPinterestSquare,
  FaApple,
  FaAndroid,
} from "react-icons/fa";
import { MdLocationPin, MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";

export const footMenu = [
  {
    id: 1,
    title: "FAQs",
    path: "/faqs",
  },
  {
    id: 2,
    title: "Reviews",
    link: "Help & Support",
    path: "/reviews",
    // menu: [
    //   {
    //     id: 1,
    //     link: "Help & Support",
    //     path: "/",
    //   },
    //   {
    //     id: 2,
    //     link: "A2Z Foundation",
    //     path: "/",
    //   },
    // ],
  },
  {
    id: 3,
    title: "Privacy Policy",
    link: "Privacy Policy",
    path: "/Privacy-Policy",
    // menu: [
    //   {
    //     id: 1,
    //     link: "Privacy Policy",
    //     path: "/",
    //   },
    //   {
    //     id: 2,
    //     link: "Cookie Setting",
    //     path: "/",
    //   },
    // ],
  },
  {
    id: 4,
    title: "Terms And Services",
    link: "Desktop App",
    path: "/TAC",
    // menu: [
    //   {
    //     id: 1,
    //     link: "Desktop App",
    //     path: "/",
    //   },
    //   {
    //     id: 2,
    //     link: "Cookies Policy",
    //     path: "/",
    //   },
    //   {
    //     id: 3,
    //     link: "Enterprise Solutions",
    //     path: "/",
    //   },
    // ],
  },
  {
    id: 4,
    title: "Cookie",
    link: "Desktop App",
    path: "/Cookies",
    // menu: [
    //   {
    //     id: 1,
    //     link: "Desktop App",
    //     path: "/",
    //   },
    //   {
    //     id: 2,
    //     link: "Cookies Policy",
    //     path: "/",
    //   },
    //   {
    //     id: 3,
    //     link: "Enterprise Solutions",
    //     path: "/",
    //   },
    // ],
  },
  {
    id: 5,
    title: "About US",
    link: "Desktop App",
    path: "/Aboutus",
    // menu: [
    //   {
    //     id: 1,
    //     link: "Desktop App",
    //     path: "/",
    //   },
    //   {
    //     id: 2,
    //     link: "Cookies Policy",
    //     path: "/",
    //   },
    //   {
    //     id: 3,
    //     link: "Enterprise Solutions",
    //     path: "/",
    //   },
    // ],
  },
  {
    id: 6,
    title: "Contact US",
    link: "Desktop App",
    path: "/ContactUS",
    // menu: [
    //   {
    //     id: 1,
    //     link: "Desktop App",
    //     path: "/",
    //   },
    //   {
    //     id: 2,
    //     link: "Cookies Policy",
    //     path: "/",
    //   },
    //   {
    //     id: 3,
    //     link: "Enterprise Solutions",
    //     path: "/",
    //   },
    // ],
  },
];
export const contactUS = [
  {
    id: 1,
    icon: <MdLocationPin />,
    text: "59, New Rampura Circle Near BSNL Tower Udaipur, Rajasthan 313001",
    path: "/",
  },
  {
    id: 2,
    icon: <MdEmail />,
    text: "a2z@gmail.com",
    path: "mailto:a2z@gmail.com",
  },
  {
    id: 3,
    icon: <FaPhoneAlt />,
    text: "+917976768772",
    path: "tel:+917976768772",
  },
  //   {
  //     id: 4,
  //     icon: <FaLinkedinIn />,
  //     path: "/",
  //   },
];
export const footSocial = [
  {
    id: 1,
    icon: <FaFacebookF />,
    path: "/",
  },
  {
    id: 2,
    icon: <FaTwitter />,
    path: "/",
  },
  {
    id: 3,
    icon: <FaInstagram />,
    path: "/",
  },
  {
    id: 4,
    icon: <FaLinkedinIn />,
    path: "/",
  },
  {
    id: 5,
    icon: <FaPinterestSquare />,
    path: "/",
  },
];

export const footaApp = [
  {
    id: 1,
    icon: <FaApple />,
    path: "/",
  },
  {
    id: 2,
    icon: <FaAndroid />,
    path: "/",
  },
];
