import React from "react";
import '../../style/partials/components/_loader.scss'


function Loader() {
    return (
        <>
            <div className="load mt-5">
                <div className="loader" />
            </div>
        </>
    )
}

export default Loader