import React, { useContext, Suspense } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Home from "./components/Home/Home";
import Studenthome from "./components/Student/Home/Home";
import CounsellorHome from "./components/Counsellor/Home/Home";
import SelectCounselling from "./components/Common/SelectCounselling";
import Sessiondetails from "./components/Student/Studentsession/Sessiondetails";
import Conformation from "./components/Counsellor/conformationsession/conformationsession";
import Login from "./components/form/Login";
import Register from "./components/form/Register";
import Eventcreate from "./components/Counsellor/EventCreate/Eventcreate";
import PageNotfound from "./components/Pagenotfound/Pagenotfound";
import CounsellorProfile from "./components/Counsellor/CounsellorProfile/CounsellorProfile";
import UpdateProfile from "./components/Counsellor/CounsellorProfile/Update-Profile";
import StudentProfile from "./components/Student/StudentProfile/StudentProfile";
import EditProfile from "./components/Student/StudentProfile/EditProfile";
import StudentWallet from "./components/Student/Wallet/Studentwallet";
import UpcomingStudentSession from "./components/Student/UpcomingSession/UpcomingStudentSession";
import CounsellorUpcomingSession from "./components/Counsellor/upcomingSession/UpcomingSession";
import CompletedSession from "./components/Student/CompletedSession/completedsessions";
import CounsellorResult from "./components/SeacrhResult/CounsellorResult";
import HowItWorks from "./components/HowItWorks/HowItWorks";
import OurExperts from "./components/OurExpert/Experts";
import WalletPayment from "./components/Student/SessionPayment/BidPayment";
import Aboutus from "./components/Aboutus/Aboutus";
import Privacypolicy from "./components/PrivacyPolicy/Privacypolicy";
import Cookies from "./components/Cookies/Cookies";
import TAC from "./components/TermsAndServices/Termsandservices";
import Faqs from "./components/AllFAQ/AllFaqs";
import Reviews from "./components/Reviews/Reviews";
import AcademicSuccess from "./components/Categories/AcademicSuccess";
import AIServices from "./components/Categories/AIServices";
import Business from "./components/Categories/Business";
import Data from "./components/Categories/Data";
import DigitalMarketing from "./components/Categories/DigitalMarketing";
import FinancialWellness from "./components/Categories/FinancialWellness";
import GraphicsAndDesign from "./components/Categories/GraphicsAndDesign";
import HealthAndWellbeing from "./components/Categories/HealthAndWellbeing";
import Lifestyle from "./components/Categories/Lifestyle";
import MusicAndAudio from "./components/Categories/MusicAndAudio";
import Photography from "./components/Categories/Photography";
import ProgrammingAndTech from "./components/Categories/ProgrammingAndTech";
import RelationshipCounseling from "./components/Categories/RelationshipCounseling";
import VideoAndAnimation from "./components/Categories/VideoAndAnimation";
import WritingAndTranslation from "./components/Categories/WritingAndTranslation";
import CompletedCounsellor from "./components/Counsellor/CompletedSessions/completedSessions";
import Active from "./components/Home/Activation";
import Verify from "./components/Home/VerifyFirst";
import WhyA2z from "./components/WhyChooseUs/WhyChooseUs";
import CounseleeTransaction from "./components/Student/CounseleeTransaction/CounseleeTransaction";
import CounsellorTransaction from "./components/Counsellor/CounsellorTransactions/CounsellorTransaction";
import Contactus from "./components/ContactUS/Contactus";
import CounseleeNotifications from "./components/Student/Notifications/AllNotifications";
import CounsellorNotifications from "./components/Counsellor/Notifications/AllNotifications";
import UserContext from "./contexts/UserContext";
import PopularSessions from "./components/Student/PopularSkillsSessions/PopularSessions";
import SubCategories from "./components/Student/ProductSessions/ProductsSessions";
import Topskills from "./components/TopSkills/Topskills";
import Reset from "./components/Password/Reset";
import ForgotPassword from "./components/Password/Resetting";
import CounsellorPortfo from "./components/Counsellor/CounsellorProfile/CounsellorPortfo";
// import Rating from "../src/components/Rating/Rating";

function App() {
  const { userData } = useContext(UserContext);
  console.log(userData);
  return (
    <>
      <Router className="container">
        <Suspense fallback={<div>Loading...</div>}>
          <Header />
          <Routes>
            <Route path="*" element={<PageNotfound />} />
            <Route
              exact
              path="/"
              element={
                userData?.flag === "STUDENT" ? (
                  <Studenthome />
                ) : userData?.flag === "COUNSELLOR" ? (
                  <CounsellorHome />
                ) : (
                  <Home />
                )
              }
            />
            <Route path="/Register" element={<Register />} />
            <Route path="/Login" element={<Login />} />
            <Route path="/Students" element={<Studenthome />} />
            <Route path="/Aboutus" element={<Aboutus />} />
            <Route path="/Cookies" element={<Cookies />} />
            <Route path="/SessionPay" element={<WalletPayment />} />
            <Route path="/TAC" element={<TAC />} />
            <Route path="/faqs" element={<Faqs />} />
            <Route path="/Counsellor" element={<CounsellorHome />} />
            <Route path="/select" element={<SelectCounselling />} />
            <Route path="/Session-details" element={<Sessiondetails />} />
            <Route path="/conformation" element={<Conformation />} />
            <Route path="/Eventcreate" element={<Eventcreate />} />
            <Route path="/Counsellor-profile" element={<CounsellorProfile />} />
            <Route path="/Edit-profile" element={<UpdateProfile />} />
            <Route path="/Student-profile" element={<StudentProfile />} />
            <Route path="/Student-update-profile" element={<EditProfile />} />
            <Route path="/Session-Result" element={<CounsellorResult />} />
            <Route path="/Student-wallet" element={<StudentWallet />} />
            <Route path="/How-It-Works" element={<HowItWorks />} />
            <Route path="/Our-Experts" element={<OurExperts />} />
            <Route path="/Privacy-Policy" element={<Privacypolicy />} />
            <Route path="/reviews" element={<Reviews />} />
            <Route path="/activated" element={<Active />} />
            <Route path="/verification" element={<Verify />} />
            <Route path="/whya2z" element={<WhyA2z />} />
            <Route
              path="/popular-skillsessions"
              element={<PopularSessions />}
            />
            <Route path="/changePassword/:id" element={<Reset />} />
            <Route
              path="/counseleetransaction"
              element={<CounseleeTransaction />}
            />
            <Route path="/subcategories" element={<SubCategories />} />
            <Route
              path="/counsellortransaction"
              element={<CounsellorTransaction />}
            />
            <Route path="/Contactus" element={<Contactus />} />

            <Route
              path="/UpcomingSessionStudent"
              element={<UpcomingStudentSession />}
            />
            <Route
              path="/CounsellorUpcomingSession"
              element={<CounsellorUpcomingSession />}
            />
            <Route path="/topskills" element={<Topskills />} />
            <Route path="/CompletedSession" element={<CompletedSession />} />
            <Route path="/completed" element={<CompletedCounsellor />} />
            <Route
              path="/counseleeNotification"
              element={<CounseleeNotifications />}
            />
            <Route
              path="/counsellorNotification"
              element={<CounsellorNotifications />}
            />
            <Route path="/forgot" element={<ForgotPassword />} />
            {/* {userData?.isProfile  ( */}
            <Route path="/portf" element={<CounsellorPortfo />} />
            {/* )} */}
          </Routes>

          <Footer />
        </Suspense>
      </Router>
    </>
  );
}

export default React.memo(App);
