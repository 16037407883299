const counsellormenu = [
  {
    title: "Add New Counselling",
    url: "/select",
    cName: "nav-links",
  },
  {
    title: "My Sessions",
    url: "/Counsellor",
    cName: "nav-links",
  },
  {
    title: "Upcoming Sessions",
    url: "/CounsellorUpcomingSession",
    cName: "nav-links",
  },
  {
    title: "Complete Session",
    url: "/completed",
    cName: "nav-links",
  },
  {
    title: "Reviews",
    url: "/reviews",
    cName: "nav-links",
  },
  // {
  //   title: "FAQs",
  //   url: "/faqs",
  //   cName: "nav-links",
  // },
  {
    title: "About US",
    url: "/Aboutus",
    cName: "nav-links",
  },
];

export default counsellormenu;
