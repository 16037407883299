import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { useNavigate, useLocation, Link } from "react-router-dom";
import axios from "axios";
import { API } from "../../../api-config";
import ListGroup from "react-bootstrap/ListGroup";
import "../../../style/partials/components/_popularsess.scss";
import ai from "../../../images/ARTIFICIALINTELLIGENCE.jpg";
import SectionsHead from "../../Home/Heading";

const PopularSessions = () => {
  const navigate = useNavigate();
  const emp = useLocation();
  const [sessions, setSessions] = useState([]);
  const loadSessions = async () => {
    console.log(emp);
    if (emp.state.entity == "skill") {
      const response = await axios.post(
        `${API}/api/session/popularSkillsSessions`,
        {
          skill: emp.state.skill,
        }
      );
      setSessions((prevState) => response.data.sessions);
      console.log("SKILSES", response.data.sessions);
    } else if (emp.state.entity == "counsellor") {
      console.log(emp);
      const response = await axios.post(`${API}/api/session/get-session`, {
        counsellorId: emp.state.counsellor,
      });
      console.log(response.data);
      setSessions((prevState) => response.data);
      console.log("SKILSES", response.data.getsession);
    }
  };
  useEffect(() => {
    loadSessions();
  }, []);
  return (
    <div className="d-flex justify-content-center align-items-center sess">
      <div className="container">
        <SectionsHead heading="Popular Sessions" />
        <div className="row d-flex justify-content-center align-items-center">
          {sessions &&
            sessions.map((item) => (
              <Card
                style={{
                  border: "4px solid",
                  borderColor: "#3a4f68",
                  textAlign: "center",
                  padding: "0px",
                }}
                className="col-lg-3 col-md-6 col-sm-12 top-1  m-2 d-flex justify-content-center align-items-center"
                // style={{ backgroundColor: "steelblue" }}
              >
                <Card.Img
                  variant="top"
                  src={ai}
                  style={{ maxHeight: "200px" }}
                />
                <Card.Body>
                  <Card.Title
                    style={{ textAlign: "center" }}
                  >{`Title : ${item.sessiontitle}`}</Card.Title>

                  <Card.Text>{`Session Cost : ${item.sessionCost}  ${item.sessionCurrency}`}</Card.Text>
                  {/* <table className="table text-dark text-center">
                    <thead>
                      <tr>
                        <th>Slot</th>
                        <th>Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {item.sessionNumber.map((i) => (
                        <tr>
                          <td>{`${i.sessions}`}</td>
                          <td>{`${i.Price}`}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table> */}
                </Card.Body>
                <Button
                  onClick={() => {
                    console.log(item);
                    navigate("/Session-details", {
                      state: {
                        id: item._id,
                        bid: item.sessionNumber,
                        cost: item.sessionCost,
                        defaultCurrency: item.defaultCurrency
                          ? item.defaultCurrency
                          : "INR",
                      },
                    });
                  }}
                >
                  Check Slots
                </Button>
                <Button
                  style={{ backgroundColor: "#3a4f68", width: "fit-content" }}
                  className="m-1 text-white"
                  onClick={() => {
                    console.log(item);
                    navigate("/Session-details", {
                      state: {
                        id: item._id,
                        bid: item.sessionNumber,
                        cost: item.sessionCost,
                        defaultCurrency: item.defaultCurrency
                          ? item.defaultCurrency
                          : "INR",
                      },
                    });
                  }}
                >
                  Book Session
                </Button>
              </Card>
            ))}
        </div>
      </div>
    </div>
  );
};

export default PopularSessions;
