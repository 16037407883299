const Walletadd = [
    {
      id: 1,
      amount: 500,
    },
    {
      id: 2,
      amount: 1000,
    },
    {
      id: 3,
      amount: 2000,
    },
    {
      id: 4,
      amount: 3000,
    },
  ]

  export default Walletadd;
