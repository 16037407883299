import React, { useState, useEffect, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import completed from "../../../style/partials/Students/_completedSess.scss";
import { Rating } from "react-simple-star-rating";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { API } from "../../../api-config.js";
import SectionsHead from "../../Home/Heading";
import Button from "../../Elements/Button.js";
import axios from "axios";
import UserContext from "../../../contexts/UserContext";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import socket from "../../../socket/socketio.js";
// import { throttle } from "lodash";

let offset = 1;
let feedOffset = 0;
let wait = false;

const CompletedSession = () => {
  let token = "";
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  let limit = 4;
  const [state, setstate] = useState([]);

  // const [totalProposals, setTotalProposals] = useState([]);
  const [pagesCount, setPagesCount] = useState(0);
  const [sessionCount, setSessionCount] = useState(0);
  const [feedLen, setFeedLen] = useState();
  const [feedPages, setFeedPages] = useState();
  const [sessionRating, setSessionRating] = useState(0);
  const [counsellorRating, setCounsellorRating] = useState(0);
  const [len, setLength] = useState(0);
  const [show, setShow] = useState(false);
  const [completedlist, setCompleteList] = useState([]);
  const [sessionId, setSessionId] = useState("");
  const [counsellor, setCounsellor] = useState("");
  const [feed, setFeed] = useState([]);

  const handleClose = () => setShow(false);
  const navigate = useNavigate();
  const { userData } = useContext(UserContext);

  const loadFeed = async () => {
    const feeds = await axios.get(`${API}/api/feedback/get-Studentfeedback`, {
      headers: {
        userId: userData.userId,
        offset: feedOffset,
        token: userData.token,
      },
    });
    console.log("dfvjhvbsh", feeds);
    setFeed((prevState) => feeds.data.allRating);
    setFeedLen(feeds.data.count);
    setFeedPages(Math.ceil(feeds.data.count / 4));
    console.log(feeds);
  };
  const completeList = async () => {
    console.log(userData);
    const response = await axios.get(`${API}/api/completedSession/list`, {
      headers: {
        userId: userData.userId,
        offset: offset,
        token: userData.token,
      },
    });
    setCompleteList((prevState) => response.data.allCompleted);
    setstate((prevState) => response.data.allCompleted);
    // setLength(Math.ceil(response.data.count / limit));
    // console.log("COMPLETED SESSIONS", response.data);
    // console.log(response.data.length);
    // setSessionCount(response.data.length);
    setPagesCount((prevState) => Math.ceil(response.data.count / 4));
  };

  useEffect(() => {
    try {
      token = localStorage.getItem("userData").token;

      if (!userData.isProfile) {
        navigate("/Students");
      }
      completeList();
      loadFeed();
    } catch (error) {
      console.log(error);
    }
  }, []);

  const pageNum = (num) => {
    console.log("num", num);
    offset = num;
    completeList();
  };
  const accomodatePage = (clickedPage) => {
    if (clickedPage <= 1) {
      return clickedPage + 1;
    }
    if (clickedPage >= pagesCount) {
      return clickedPage - 1;
    }
    return clickedPage;
  };
  const submitData = async (data) => {
    try {
      console.log("HANDLING  Submitting form...");
      setShow(false);
      console.log(
        "data",
        data,
        "session rating",
        sessionRating,
        counsellorRating,
        counsellor,
        sessionId
      );
      const resultFeedback = await axios.post(
        `${API}/api/feedback/insert-feedback`,
        {
          data,
          sessionRating,
          counsellorRating,
          sessionId,
        }
      );
      let message = `${userData.fName} ${userData.lName} submitted a feedback for your session`;
      let userid = {
        _id: counsellor,
      };
      let notificationDetails = {
        msg: message,
        entity: "Feedback",
      };
      const notificationResponse = await axios.post(
        `${API}/api/notifications/update`,
        { details: notificationDetails, userData: userid },
        {
          headers: { token: userData.token },
        }
      );
      socket.emit("noti", { _id: counsellor, msg: message });
      console.log("FEEDBACK ", resultFeedback);
      console.log(resultFeedback, " RATING OF COUNSELLOR ", counsellor);
      const resultRating = await axios.post(`${API}/api/rating/insert-rating`, {
        counsellorId: counsellor,
        rate: counsellorRating,
      });
      reset();
      console.log(resultRating);
      alertify.success("Feedback Submitted");
      completeList();
      loadFeed();
    } catch (error) {
      console.log(error);
    }
  };
  // const throttleSubmitData = throttle(submitData, 2000);

  return (
    <>
      <Modal show={show}>
        <Modal.Header
          closeButton
          onClick={() => {
            setShow(false);
          }}
        />
        <form
          className="p-3"
          onSubmit={handleSubmit((data) => {
            if (wait) {
              return;
            }
            wait = true;
            setTimeout(() => {
              wait = false;
            }, 5000);
            submitData(data);
          })}
        >
          <div className="overlay" id="overlay ">
            <h3>Session Review & Feedback</h3>
            <textarea
              {...register("Rating1", {
                required: true,
                min: 500,
              })}
            ></textarea>
            <h3>Give rating to this session</h3>
            <Rating
              onClick={(rate) => {
                setSessionRating(rate);
              }}
            />
            <br />
            <br />
            {/* <h3>Counsellor Review & Feedback</h3> */}
            {/* <textarea {...register("Rating2", {
                                        required: true,
                                        min: 500,
                                    })} ></textarea> */}
            <h3>Rate counsellor for this session</h3>
            <Rating
              className="rating"
              onClick={(rate) => {
                setCounsellorRating(rate);
              }}
            />
            <br />
            <br />
            <button>Submit</button>
          </div>
        </form>
      </Modal>
      <div className="completed-session container text-center mt-5 mb-5">
        <SectionsHead heading="Completed Sessions" />
        {state && state.length > 0 ? (
          <div className="row">
            {state.map((item, index) => {
              return (
                <div className=" col-xl-4 col-md-6 mb-3">
                  <div className="col-12 top-skill-ditel-box ">
                    <div className="row">
                      <div className="ses-detail col-8">
                        <div>
                          <span> Session Title</span>:
                          <span className="info-detail">
                            {" "}
                            {item.sessiontitle}
                          </span>
                        </div>
                        <div>
                          <span> Session Counsellor</span>:{" "}
                          <span className="info-detail">
                            {item.counsellorId.fName}
                          </span>
                        </div>
                        <div>
                          <span>Session Skill:</span>
                          {item.skills.map((items, index) =>
                            index != item.skills.length - 1 ? (
                              <span className="info-detail">
                                {`${items.skill} ,`}
                              </span>
                            ) : (
                              <span className="info-detail">
                                {`${items.skill} `}
                              </span>
                            )
                          )}
                        </div>
                      </div>
                      {!item.feedback && (
                        <div className="feedbackButton col-4">
                          <button
                            className="feedBut"
                            onClick={() => {
                              setShow(true);
                              setSessionId(item.bidReqId);
                              setCounsellor(item.counsellorId._id);
                            }}
                            key={item.sessionId}
                          >
                            FEEDBACK
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : feed.length > 0 ? (
          <h6>Feedbacks Submitted for all</h6>
        ) : (
          <h6>No Sessions Available</h6>
        )}
        {pagesCount > 1 && (
          <div>
            {offset > 1 && (
              <button
                className="index-btn mb-2 mt-2"
                onClick={() => {
                  pageNum(offset - 1 >= 1 ? offset - 1 : 1);
                }}
              >
                {<i className="fa-solid fa-chevron-left"></i>}
              </button>
            )}
            {(() => {
              const buttons = [];

              if (pagesCount >= 3) {
                const currentPageNum = accomodatePage(offset);
                for (let i = -1; i < 2; i++) {
                  buttons.push(
                    <button
                      key={currentPageNum + i}
                      className="index-btn mb-2 mt-2"
                      onClick={() => {
                        pageNum(currentPageNum + i);
                      }}
                    >
                      {currentPageNum + i}
                    </button>
                  );
                }
              } else {
                for (let i = 0; i < pagesCount; i++) {
                  buttons.push(
                    <button
                      key={1 + i}
                      className="index-btn mb-2 mt-2"
                      onClick={() => {
                        pageNum(1 + i);
                      }}
                    >
                      {1 + i}
                    </button>
                  );
                }
              }
              return buttons;
            })()}
            {offset != pagesCount && pagesCount > 1 && (
              <button
                className="index-btn mb-2 mt-2"
                onClick={() => {
                  pageNum(offset + 1 <= pagesCount ? offset + 1 : pagesCount);
                }}
              >
                {<i className="fa-solid fa-chevron-right"></i>}
              </button>
            )}
          </div>
        )}
      </div>
      <div className="container feedback-sessions mt-5 mb-5">
        <SectionsHead heading="Feedbacks" />
        <div className="row">
          {feed.length > 0 ? (
            feed.map((item, index) => (
              <div key={index} className="completed-session col-lg-6 col-md-12">
                <div className="complete text-center">
                  <div className="row detail-btn">
                    <div className="col ses-detail col-8">
                      <div>
                        <b>Session</b>:{" "}
                        {item.sessionid?.sessionid?.sessiontitle || "N/A"}
                      </div>
                      <div>
                        <b>Counsellor</b>: {item.counsellorId?.fName || "N/A"}
                      </div>
                      <div>
                        <b>Feedback</b>: {item.feedbackDesc || "N/A"}
                      </div>
                      <div>
                        <b>Session Rating</b>: {item.sessionRating || "N/A"}
                      </div>
                      <div>
                        <b>Counsellor Rating</b>:{" "}
                        {item.counsellorRating || "N/A"}
                      </div>
                      <div>
                        <b>Session Date</b>:{" "}
                        {item.createdAt
                          ? new Date(item?.createdAt)
                              .toDateString()
                              .slice(4, 15)
                          : "N/A"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="heading">No Pending Sessions</div>
          )}
          {feedPages > 1 && feedOffset < feedPages - 1 && (
            <Button
              onClick={() => {
                feedOffset = feedOffset + 1;
                loadFeed();
              }}
              context="Load More"
            />
          )}
        </div>
      </div>

      {/* <div className="container feedback-sessions mt-5 mb-5">
        <h3>Feedbacks</h3>
        <div className="row">
          {feed.length > 0 ? (
            feed.map((item, index) => {
              console.log(item);
              return (
                <div className="completed-session col-lg-6 col-md-12">
                  <div className="complete text-center ">
                    <div className="row detail-btn">
                      <div className="col ses-detail col-8">
                        <div>
                          <b> Session </b>:{" "}
                          {item.sessionid.sessionid.sessiontitle}
                        </div>
                        <div>
                          <b> Counsellor </b>: {item.counsellorId.fName}
                        </div>
                        <div>
                          <b> Feedback </b>: {item.feedbackDesc}
                        </div>
                        <div>
                          <b> Rating</b>: {item.sessionRating}
                        </div>
                        <div>
                          <b> Session Date</b>:{" "}
                          {item.createdAt.toString().substring(0, 10)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="heading">No Pending Sessions</div>
          )}
          {feedPages > 1 && feedOffset < feedPages - 1 && (
            <button
              onClick={() => {
                feedOffset = feedOffset + 1;
                loadFeed();
              }}
            >
              Load More
            </button>
          )}
        </div>
      </div> */}
    </>
  );
};

export default CompletedSession;
