import React from "react";
import SectionsHead from "../Home/Heading";
import influ from "../../images/influencer.png";
import privacy from "../../images/privacy-policy.png";
import support from "../../images/24-7.png";
import reading from "../../images/reading-book.png";
import friends from "../../images/friends.png";

import "../../style/partials/components/_whychooseus.scss";

const WhyChooseUs = () => {
  return (
    <div className="container">
      <SectionsHead heading="Why Choose Us" />
      <h4 className="way-choos-contened">
        A2Z provides excellent and best online counselling worldwide. Our online
        counsellors speak different languages and offer confidential counselling
        through video.
      </h4>

      <ul className="why-chooses">
        <li>
          <div className="why-chooses-img-tittel">
            <div className="why-chooses-img">
              <img
                src={support}
                alt="support"
                width="40"
                height="40"
                loading="lazy"
              />
            </div>
            <div className="why-choose-text">
              <h6>Count on 24/7 support:</h6>
              <p>
                Our round-the-clock support team is available to help anytime,
                anywhere.
              </p>
            </div>
          </div>
        </li>
        <li>
          <div className="why-chooses-img-tittel">
            <div className="why-chooses-img">
              <img
                src={friends}
                alt="friends"
                width="40"
                height="40"
                loading="lazy"
              />
            </div>
            <div className="why-choose-text">
              <h6>User-Friendly Interface:</h6>
              <p>
                A2Z offers a user-friendly interface, making navigation and
                engagement on the platform intuitive and enjoyable.
              </p>
            </div>
          </div>
        </li>
        <li>
          <div className="why-chooses-img-tittel">
            <div className="why-chooses-img">
              <img
                src={privacy}
                alt="privacy"
                width="40"
                height="40"
                loading="lazy"
              />
            </div>
            <div className="why-choose-text">
              <h6>Privacy and Security:</h6>
              <p>
                A2Z prioritises user privacy and security. Strong measures,
                including encryption and privacy protection, are in place to
                ensure the confidentiality of user data.
              </p>
            </div>
          </div>
        </li>
        <li>
          <div className="why-chooses-img-tittel">
            <div className="why-chooses-img">
              <img
                src={reading}
                alt="reading"
                width="40"
                height="40"
                loading="lazy"
              />
            </div>
            <div className="why-choose-text">
              <h6>Continuous Learning Beyond Sessions:</h6>
              <p>
                A2Z provides continuous learning opportunities beyond individual
                sessions. Stay engaged with exclusive content, ensuring users
                remain informed about the latest trends, research, and tools
                relevant to their counselling objectives.
              </p>
            </div>
          </div>
        </li>
        <li>
          <div className="why-chooses-img-tittel">
            <div className="why-chooses-img">
              <img
                src={influ}
                alt="influ"
                width="40"
                height="40"
                loading="lazy"
              />
            </div>
            <div className="why-choose-text">
              <h6>Expert Support:</h6>
              <p>
                Our friendly counsellors are really good at helping with all
                sorts of things like school, feelings, finances or even dreams
                etc.
              </p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default WhyChooseUs;
