import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { API } from "../../../api-config.js";
import axios from "axios";
import UserContext from "../../../contexts/UserContext";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import SectionsHead from "../../Home/Heading.js";
import Button from "../../Elements/Button.js";
// import { throttle } from "lodash";

let offset = 1;
let feedOffset = 0;
let wait = false;
const CompletedSessions = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  // let upperLimit = 4;
  // let lowerLimit = 0;
  const [state, setstate] = useState([]);
  // const [totalProposals, setTotalProposals] = useState([]);
  const [pagesCount, setPagesCount] = useState(0);
  const [sessionCount, setSessionCount] = useState(0);
  const [sessionRating, setSessionRating] = useState(0);
  const [counsellorRating, setCounsellorRating] = useState(0);
  const [show, setShow] = useState(false);
  const [completedlist, setCompleteList] = useState([]);
  const [feedPages, setFeedPages] = useState();
  const [sessionId, setSessionId] = useState("");
  const [feedLen, setFeedLen] = useState();
  const [counsellor, setCounsellor] = useState("");
  const [feed, setFeed] = useState([]);
  const handleClose = () => setShow(false);
  const navigate = useNavigate();
  const { userData } = useContext(UserContext);

  const loadFeed = async () => {
    const feeds = await axios.get(`${API}/api/feedback/get-Studentfeedback`, {
      headers: {
        userId: userData.userId,
        entity: "counsellor",
        offset: feedOffset,
        token: userData.token,
      },
    });
    setFeed((prevState) => feeds.data.allRating);
    setFeedLen(feeds.data.count);
    setFeedPages(Math.ceil(feeds.data.count / 4));
    console.log(feeds);
  };
  const completeList = async () => {
    console.log(userData);
    const response = await axios.get(`${API}/api/completedSession/list`, {
      headers: {
        userId: userData.userId,
        entity: "counsellor",
        offset: offset,
        token: userData.token,
      },
    });
    setCompleteList(response.data.allCompleted);
    setstate(response.data.allCompleted);
    console.log("COMPLETED SESSIONS", response.data);
    setSessionCount(response.data.length);
    setPagesCount(Math.ceil(response.data.count / 4));
  };
  console.log(feedOffset);
  useEffect(() => {
    try {
      if (!userData.isProfile) {
        navigate("/Counsellor");
      }
      completeList();
      loadFeed();
    } catch (error) {
      console.log(error);
    }
  }, []);

  const pageNum = (num) => {
    console.log("num", num);
    offset = num;

    completeList();
  };
  const accomodatePage = (clickedPage) => {
    if (clickedPage <= 1) {
      return clickedPage + 1;
    }
    if (clickedPage >= pagesCount) {
      return clickedPage - 1;
    }
    return clickedPage;
  };

  return (
    <>
      <div className="completed-session container text-center">
        <SectionsHead heading="Completed Sessions" />
        {state.length > 0 ? (
          <div className="row">
            {state.map((item, index) => {
              return (
                <div className=" col-xl-4 col-md-6 mb-3">
                  <div className="col-12 top-skill-ditel-box ">
                    <div className="row">
                      <div className="ses-detail col-8">
                        <div>
                          <span> Session Title</span>:
                          <span className="info-detail">
                            {" "}
                            {item.sessiontitle}
                          </span>
                        </div>
                        <div>
                          <span> Student</span>:{" "}
                          <span className="info-detail">
                            {item.userId.fName}
                          </span>
                        </div>
                        <div>
                          <span> Session Skill</span>:{" "}
                          {item.skills.map((it) => (
                            <p className="">{it.skill}</p>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <h6>No Sessions Available</h6>
        )}
        {pagesCount > 1 && (
          <div>
            {offset > 1 && (
              <button
                className="index-btn mb-2 mt-2"
                onClick={() => {
                  pageNum(offset - 1 >= 1 ? offset - 1 : 1);
                }}
              >
                {<i className="fa-solid fa-chevron-left"></i>}
              </button>
            )}
            {(() => {
              const buttons = [];

              if (pagesCount >= 3) {
                const currentPageNum = accomodatePage(offset);
                for (let i = -1; i < 2; i++) {
                  buttons.push(
                    <button
                      key={currentPageNum + i}
                      className="index-btn mb-2 mt-2"
                      onClick={() => {
                        pageNum(currentPageNum + i);
                      }}
                    >
                      {currentPageNum + i}
                    </button>
                  );
                }
              } else {
                for (let i = 0; i < pagesCount; i++) {
                  buttons.push(
                    <button
                      key={1 + i}
                      className="index-btn mb-2 mt-2"
                      onClick={() => {
                        pageNum(1 + i);
                      }}
                    >
                      {1 + i}
                    </button>
                  );
                }
              }
              return buttons;
            })()}
            {offset != pagesCount && (
              <button
                className="index-btn mb-2 mt-2"
                onClick={() => {
                  pageNum(offset + 1 <= pagesCount ? offset + 1 : pagesCount);
                }}
              >
                {<i className="fa-solid fa-chevron-right"></i>}
              </button>
            )}
          </div>
        )}
      </div>
      <div className="container">
        <SectionsHead heading="Feedbacks" />
        <div className="text-center row">
          {feed.length > 0 ? (
            feed.map((item, index) => {
              console.log(item);
              return (
                <div className="completed-session col-lg-6 col-md-12">
                  <div className="complete text-center ">
                    <div className="row detail-btn">
                      <div className="col ses-detail col-8">
                        <div>
                          <b> Session </b>:{" "}
                          {item?.sessionid?.sessionid?.sessiontitle}
                        </div>
                        <div>
                          <b> Counsellor </b>: {item?.counsellorId?.fName}
                        </div>
                        <div>
                          <b> Feedback </b>: {item?.feedbackDesc}
                        </div>
                        <div>
                          <b>Counsellor Rating</b>: {item?.counsellorRating}
                        </div>
                        <div>
                          <b>Session Rating</b>: {item?.sessionRating}
                        </div>
                        <div>
                          <b> Session Date</b>:{" "}
                          {new Date(item?.createdAt)
                            .toDateString()
                            .slice(4, 15)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <h6>No Feedbacks Available</h6>
          )}

          {feedOffset >= 1 && (
            <Button
              className="btn btn-outline-primary custom-btn btn-8"
              onClick={() => {
                feedOffset = feedOffset - 1;
                loadFeed();
              }}
              context="Prev"
            />
          )}
          {feedPages > 1 && feedOffset < feedPages - 1 && (
            <Button
              className="btn btn-outline-primary custom-btn btn-8"
              onClick={() => {
                feedOffset = feedOffset + 1;
                loadFeed();
              }}
              context="Next"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default React.memo(CompletedSessions);
